import gql from 'graphql-tag';

export const ALL_PEDIDOS = gql`
  query allPedidos($fecha: Date $hora: Time) {
    allPedidos(fecha:$fecha hora:$hora ){
      edges{
        node{
          id
          fecha
          hora
        owner{
        id
        username
        userUsuarioempresa{
          edges{
            node{
              empleado{
                id
                nombre 
                apellidoPaterno
                apellidoMaterno
                planta{
                  id
                  nombre
                }
                departamento{
                  id
                  nombre
                  empresa{
                    nombre
                  }
                }
              }
            }
          }
        }
      }
          estado
          tipoPedido{
            id
            nombre
            origen
            destino
          }
           pedidoTraslado{
            edges{
              node{
                id
                empleado{
                  numero
                  nombre
                  apellidoPaterno
                  apellidoMaterno
                  domicilio{
                    id
                    nombre
                    numero
                    colonia{
                      id
                      nombre
                      cp
                    }
                  }
                }
                chofer{
                  id
                  nombre
                  apellidoPaterno
                  apellidoMaterno
                  estado
                }
              }
            }
      }
          
        }
      }
    }
}
`;


export const PEDIDO = gql`
  query pedido($id: ID!) {
    pedido(id:$id) {
      id
          fecha
          hora
          estado
          tipoPedido{
            id
            nombre
            origen
            destino
          }
  }
}
`;


