import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_MUNICIPIOS } from '../graphql/query/municipioQueries';
import { CREATE_MUNICIPIO, UPDATE_MUNICIPIO, DELETE_MUNICIPIO } from '../graphql/mutation/municipioMutation';


Vue.use(Vuex);

const state = () => ({
    municipios: [],
    pagination: [],
});

const getters = {
    municipios: state => state.municipios,
};

const mutations = {
    SET_MUNICIPIOS: (state, payload) => state.municipios = payload,
    ADD_MUNICIPIO: (state, payload) => state.municipios.push(payload),
    DELETE_MUNICIPIO: (state, payload) => state.municipios = state.municipios.filter(el => el.id !== payload.id),
    UPDATE_MUNICIPIO: (state, payload) => state.municipios = state.municipios.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allMunicipios({ commit } ) {
        try {
            const { data: { allMunicipios: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_MUNICIPIOS });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_MUNICIPIOS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createMunicipio({ commit }, payload ) {
        try {
            const { data: { createMunicipio: { municipio } } }  = await apolloClient
                .mutate({ mutation: CREATE_MUNICIPIO, variables: { ...payload }  });
            commit('ADD_MUNICIPIO', municipio);
        } catch (e) {
            console.log(e);
        }
    },
    async updateMunicipio({ commit }, payload ) {
        try {
            const { data: { updateMunicipio: { municipio } } }  = await apolloClient
                .mutate({ mutation: UPDATE_MUNICIPIO, variables: { ...payload }  });
            commit('UPDATE_MUNICIPIO', municipio);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteMunicipio({ commit }, payload ) {
        try {
            const { data: { deleteMunicipio: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_MUNICIPIO, variables: { ...payload }  });
            if (found){
                commit('DELETE_MUNICIPIO', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
