<template>
  <div id="app">
    <v-row align="center" justify="center" style="height: 100vh" dense>
      <v-lazy
          v-model="isActive"
          :options="{
          threshold: .8
        }"
          transition="fade-transition"
      >
      <v-card  hover align="center"  justify="center" color="#d3e9f8" class="ml-13 justify-center rounded-xl"
               width="300" height="350">
        <v-avatar class="mt-n16 justify-center" color="white" width="200" height="200">
          <v-img contain height="150" :src="ttn"/>
        </v-avatar>
        <v-card-title class="justify-center">TTN</v-card-title>
        <v-card-text><p align = "justify">Este módulo esta enfocado en la gestión de
          las solicitudes de traslados.</p>
        </v-card-text>
        <v-card-actions class="justify-center">

            <v-btn rounded dark color="secondary"
                   @click="()=>{this.$router.push('/')}">
              Acceder
            </v-btn>

        </v-card-actions>
      </v-card>
      </v-lazy>
      <v-lazy
          v-model="isActive"
          :options="{
          threshold: .9
        }"
          transition="fade-transition"
      >
      <v-card  hover align="center"  justify="center" color="#d3e9f8"
               class="ml-13 justify-center rounded-xl" width="300" height="350">
        <v-avatar  class="mt-n16 justify-center" color="white" width="200" height="200">
          <v-img contain height="120" :src="gmr"/>
        </v-avatar>
        <v-card-title class="justify-center">GMR</v-card-title>
        <v-card-text><p align = "justify"> Este módulo esta enfocado en la gestión de
          la empresa de seguridad.</p>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn rounded dark color="secondary"
                   @click="()=>{this.$router.push('/g')}">
              Acceder
            </v-btn>
        </v-card-actions>
      </v-card>
      </v-lazy>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ModuleLayout",
  data: ()=>({
    isActive: false,
    gmr: require('../../assets/logo-gmr.png'),
    ttn: require('../../assets/LOGO1.png'),
  }),
}
</script>

<style scoped>
#gmr {
  background: url("../../assets/logo-gmr.png");
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100% ;
}
#app {
  background: url("../../assets/nubesfondo.jpg");
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100% ;
}

</style>
