import gql from 'graphql-tag';

export const CREATE_GROUP = gql`
mutation createGroup($name: String!, $permissions: [ID], $users: [ID]){
  createGroup(input:{name:$name, permissions:$permissions, userSet:$users })
  {
    group{
      id
      name
      permissions{
        edges{
          node{
            id
            name
          }
        }
      }
    }
  }
}
`;

export const UPDATE_GROUP = gql`
mutation updateGroup($id: ID!, $name: String!, $permissions: [ID], $users: [ID]){
  updateGroup(id:$id, input:{name:$name, permissions:$permissions, userSet:$users })
  {
    group{
      id
      name
      permissions{
        edges{
          node{
            id
            name
          }
        }
      }
    }
  }
}
`;

export const DELETE_GROUP = gql`
mutation deleteGroup($id: ID!){
 deleteGroup(id:$id){
   found
 }
}
`;
