import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_GUARDIAS } from '../graphql/query/guardiaQueries';
import { CREATE_GUARDIA, UPDATE_GUARDIA, DELETE_GUARDIA } from '../graphql/mutation/guardiaMutation';


Vue.use(Vuex);

const state = () => ({
    guardias: [],
    pagination: [],
});

const getters = {
    guardias: state => state.guardias,
};

const mutations = {
    SET_GUARDIAS: (state, payload) => state.guardias = payload,
    ADD_GUARDIA: (state, payload) => state.guardias.push(payload),
    DELETE_GUARDIA: (state, payload) => state.guardias = state.guardias.filter(el => el.id !== payload.id),
    UPDATE_GUARDIA: (state, payload) => state.guardias = state.guardias.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allGuardias({ commit } ) {
        try {
            const { data: { allGuardias: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_GUARDIAS });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_GUARDIAS', nodes);
        } catch (e) {
            console.log(e);
        }
    },


    async createGuardia({ commit }, payload ) {
        try {
            const { data: { createGuardia: { guardia } } }  = await apolloClient
                .mutate({ mutation: CREATE_GUARDIA, variables: { ...payload }  });
            commit('ADD_GUARDIA', guardia);
        } catch (e) {
            console.log(e);
        }
    },
    async updateGuardia({ commit }, payload ) {
        try {
            const { data: { updateGuardia: { guardia } } }  = await apolloClient
                .mutate({ mutation: UPDATE_GUARDIA, variables: { ...payload }  });
            commit('UPDATE_GUARDIA', guardia);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteGuardia({ commit }, payload ) {
        try {
            const { data: { deleteGuardia: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_GUARDIA, variables: { ...payload }  });
            if (found){
                commit('DELETE_GUARDIA', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
