import Vue from 'vue';
import Vuex from 'vuex';
import { onLogout, onLogin, apolloClient } from '../vue-apollo';
import { LOGGED_IN_USER } from '../graphql/query/userQueries';
import { LOGIN_USER, UPDATE_PROFILE } from '../graphql/mutation/userMutation';

Vue.use(Vuex);

const state = () => ({
  user: {},
  authStatus: !!localStorage.getItem('token') || false,
  token: localStorage.getItem('token') || '',
});
const getters = {
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.authStatus,
  user: (state) => state.user,
  token: (state) => state.token,
};
const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  LOGIN_USER(state, payload) {
    state.authStatus = true;
    state.user = { ...payload };
  },
  LOGOUT_USER(state) {
    state.authStatus = false;
    state.user = {};
    state.token = '';
  },
};
const actions = {
  // async register({ commit, dispatch }, authDetails) {
  //   try {
  //     const { data } = await apolloClient
  //       .mutate({ mutation: REGISTER_USER, variables: { ...authDetails } });
  //     const token = await JSON.stringify(data.createUser.token);
  //     commit('SET_TOKEN', token);
  //     await onLogin(apolloClient, token);
  //     dispatch('setUser');
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
  // eslint-disable-next-line no-unused-vars,consistent-return
  async login({ commit, dispatch }, authDetails) {
    try {
      const { data } = await apolloClient
        .mutate({ mutation: LOGIN_USER, variables: { ...authDetails } });
      const { success } = data.tokenAuth;
      if (success) {
        const token = JSON.stringify(data.tokenAuth.token);
        await commit('SET_TOKEN', token);
        await onLogin(apolloClient, token);
        await dispatch('setUser');
      }
    } catch (e) {
      console.log(e);
    }
  },
  async setUser({ commit }) {
    const { data } = await apolloClient.query({ query: LOGGED_IN_USER });
    const user = data.me2;
    await commit('LOGIN_USER', user);
  },
  // eslint-disable-next-line no-unused-vars
  async logOut({ commit }) {
    commit('LOGOUT_USER');
    await onLogout(apolloClient);
  },
  // eslint-disable-next-line no-unused-vars
  async updateAccount({ commit, dispatch }, payload) {
    const { data: { updateAccount: { token } } } = await apolloClient.mutate(
      { mutation: UPDATE_PROFILE, variables: { ...payload } },
    );
    if (token) {
      if(token !== localStorage.getItem('token')){
        localStorage.removeItem('token');
        localStorage.setItem('token', token)
      }
      await dispatch('setUser');
    }
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
