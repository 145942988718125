<template>
  <div>
    <v-card class="mr-13">
      <v-toolbar color="primary" dark shaped>
        <v-toolbar-title>Listado de Asignaciones</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="mr-16">
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              shaped
              single-line
              hide-details
          ></v-text-field>
        </div>
        <v-btn
            color="secondary"
            absolute
            top
            right
            fab
            @click="toogleForm"
            :disabled="showForm"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
          :headers="header"
          :items="uempresas"
          :search="search"
          :loading="loading"
          loading-text="Cargando... Espere porfavor"
          @change="loadData"
          no-data-text="No existen Asignaciones registradss en el sistema."
          :item-key="uempresas.id"

      >
        <template v-slot:body="props" >
          <tbody name="slide" is="transition-group" mode="out-in" appear>
          <template >
            <tr v-for="(item, index) in props.items" :key="item.id">
              <td>{{index+1}}</td>
              <td>{{item.user.username}}</td>
              <td>{{item.empleado ? item.empleado.nombre : '-'}}</td>
              <td>{{item.empresa ? item.empresa.nombre : "-"}}</td>
              <td>{{item.chofer ? item.chofer.nombre : "-"}}</td>
              <td>{{item.guardia ? item.guardia.nombre : "-"}}</td>
              <td>
                <TableAction :item="item" @update="toogleForm" @delaction="deleteItem"/>
              </td>
            </tr>
          </template>
          </tbody>
        </template>

      </v-data-table>
    </v-card>
    <template>
      <asignacion-form v-if="showForm" :title="title" :show-form="showForm"
                   :uempresa="editItem" @close="toogleForm" />
    </template>
    <template>
      <v-dialog persistent max-width="200" transition="dialog-top-transition" v-if="menuLocked" v-model="menuLocked">
        <v-card>
          <v-card-title>Eliminar</v-card-title>
          <v-card-text>Seguro que desea eliminar la asignación de {{itemToDelete.user.username}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="terciary"
                text
                @click="deleteItemCancel"
                icon
            >
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
                color="secondary"
                text
                @click="deleteItemConfirm"
                icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

  </div>
</template>

<script>
import TableAction from "../../components/TableAction";
import AsignacionForm from "../../components/Asignacion/AsignacionForm";
import {mapGetters} from "vuex";

export default {
  name: "AsignacionList",
  components: {TableAction, AsignacionForm},
  data:()=>({
    loading: false,
    showForm: false,
    search: '',
    title:'',
    defaultEditItem: null,
    editItem: null,
    itemToDelete: null,
    menuLocked: false,
    deleteConfirmId: '',
    header: [
      { text: 'No', value: 'num', sortable: false },
      { text: 'Usuario', value: 'user.username' },
      { text: 'Supervisor Cliente', value: 'empleado.nombre' },
      { text: 'Empresa', value: 'empresa.nombre' },
      { text: 'Chofer', value: 'chofer.nombre' },
      { text: 'Guardia', value: 'guardia.nombre' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
  }),
  computed: {
    ...mapGetters(['uempresas']),
  },
  mounted() {
    if(this.uempresas.length<=0){
      this.loadData()
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("allUsuarioEmpresa");
      await this.$store.dispatch("allEmpresas");
      await this.$store.dispatch("allUsers");
      await this.$store.dispatch("allGuardias");
      await this.$store.dispatch("allChoferes");
      this.loading = false;
    },
    toogleForm(item){
      this.uempresas.indexOf(item)=== -1 ? (this.editItem= {}, this.title='Nueva Asignación') :
          (this.editItem = {...item}, this.title='Actualizar Asignación');
      this.showForm = ! this.showForm;
    },

    deleteItem(item) {
      this.itemToDelete = item
      this.menuLocked = true

    },

    async deleteItemConfirm() {
      if (this.itemToDelete !== null) {
        await this.$store.dispatch("deleteUsuarioEmpresa", this.itemToDelete);
        this.menuLocked = false;
        this.itemToDelete = null;

      }
    },

    deleteItemCancel() {
      this.menuLocked = false
      this.itemToDelete = null
    },
  },
}
</script>

<style scoped>

</style>
