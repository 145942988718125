import gql from 'graphql-tag';

export const CREATE_HORARIO=gql`
mutation CreateHorario($nombre: String! $hora: Time! $empresa: ID!){
createHorario(input: { nombre:$nombre hora:$hora empresa:$empresa}){
  horario{
      id
      nombre
      hora
      empresa{
        id
        nombre
      }
    }
}

}
`;

export const UPDATE_HORARIO=gql`
mutation UpdateHorario($id:ID! $nombre: String! $hora: Time! $empresa: ID!){
updateHorario(id: $id input: { nombre:$nombre hora:$hora empresa:$empresa}){
  horario{
      id
      nombre
      hora
      empresa{
        id
        nombre
      }
    }
}

}
`;


export const DELETE_HORARIO = gql`
mutation DeleteHorario($id: ID!){
deleteHorario(id:$id) {
  found
}
}
`;

