import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_EMPRESA_COND } from '../graphql/query/empresaCondicionQueries';
import { CREATE_EMPRESACOND, UPDATE_EMPRESACOND, DELETE_EMPRESACOND }
    from '../graphql/mutation/empresaCondicionMutation';


Vue.use(Vuex);

const state = () => ({
    econdiciones: [],
    pagination: [],
});

const getters = {
    econdiciones: state => state.econdiciones,
};

const mutations = {
    SET_ECONDICIONES: (state, payload) => state.econdiciones = payload,
    ADD_ECONDICION: (state, payload) => state.econdiciones.push(payload),
    DELETE_ECONDICION: (state, payload) => state.econdiciones = state.econdiciones.filter(el => el.id !== payload.id),
    UPDATE_ECONDICION: (state, payload) => state.econdiciones = state.econdiciones.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allEmpresaCondiciones({ commit } ) {
        try {
            const { data: { allEmpresaCondiciones: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_EMPRESA_COND });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_ECONDICIONES', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createEmpresaCondicion({ commit }, payload ) {
        try {
            const { data: { createEmpresaCondicion: { empresaCondicion } } }  = await apolloClient
                .mutate({ mutation: CREATE_EMPRESACOND, variables: { ...payload }  });
            commit('ADD_ECONDICION', empresaCondicion);
        } catch (e) {
            console.log(e);
        }
    },
    async updateEmpresaCondicion({ commit }, payload ) {
        try {
            const { data: { updateEmpresaCondicion: { empresaCondicion } } }  = await apolloClient
                .mutate({ mutation: UPDATE_EMPRESACOND, variables: { ...payload }  });
            commit('UPDATE_ECONDICION', empresaCondicion);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteEmpresaCondicion({ commit }, payload ) {
        try {
            const { data: { deleteEmpresaCondicion: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_EMPRESACOND, variables: { ...payload }  });
            if (found){
                commit('DELETE_ECONDICION', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
