import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_TRASLADOS } from '../graphql/query/trasladoQueries';
import { CREATE_TRASLADO, UPDATE_TRASLADO, DELETE_TRASLADO } from '../graphql/mutation/trasladoMutation';


Vue.use(Vuex);

const state = () => ({
    traslados: [],
    pagination: [],
});

const getters = {
    traslados: state => state.traslados,
};

const mutations = {
    SET_TRASLADOS: (state, payload) => state.traslados = payload,
    ADD_TRASLADO: (state, payload) => state.traslados.push(payload),
    DELETE_TRASLADO: (state, payload) => state.traslados = state.traslados.filter(el => el.id !== payload.id),
    CLEAN_TRASLADOS: (state) => state.traslados = [],
    UPDATE_TRASLADO: (state, payload) => state.traslados = state.traslados.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allTraslados({ commit } ) {
        try {
            const { data: { allTraslados: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_TRASLADOS });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_TRASLADOS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createTraslado({ commit }, payload ) {
        try {
            const { data: { createTraslado: { traslado } } }  = await apolloClient
                .mutate({ mutation: CREATE_TRASLADO, variables: { ...payload }  });
            commit('ADD_TRASLADO', traslado);
        } catch (e) {
            console.log(e);
        }
    },
    async updateTraslado({ commit }, payload ) {
        try {
            const { data: { updateTraslado: { traslado } } }  = await apolloClient
                .mutate({ mutation: UPDATE_TRASLADO, variables: { ...payload }  });
            commit('UPDATE_TRASLADO', traslado);
        } catch (e) {
            console.log(e);
        }
    },
    cleanTraslados({commit}){
        commit('CLEAN_TRASLADOS');
    },
    async deleteTraslado({ commit }, payload ) {
        try {
            const { data: { deleteTraslado: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_TRASLADO, variables: { ...payload }  });
            if (found){
                commit('DELETE_TRASLADO', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
