import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_GROUPS, ALL_PERMS } from '../graphql/query/groupQueries';
import { CREATE_GROUP, UPDATE_GROUP, DELETE_GROUP } from '../graphql/mutation/groupMutation';


Vue.use(Vuex);

const state = () => ({
    groups: [],
    perms: [],
    pagination: [],
});

const getters = {
    groups: state => state.groups,
    perms: state => state.perms,
};

const mutations = {
    SET_GROUPS: (state, payload) => state.groups = payload,
    SET_PERMS: (state, payload) => state.perms = payload,
    ADD_GROUP: (state, payload) => state.groups.push(payload),
    DELETE_GROUP: (state, payload) => state.groups = state.groups.filter(el => el.id !== payload.id),
    UPDATE_GROUP: (state, payload) => state.groups = state.groups.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allGroups({ commit } ) {
        try {
            const { data: { allGroups: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_GROUPS });
            const nodes = edges.map((d)=> d.node);
            console.log(nodes);
            await commit('SET_GROUPS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async allPerms({ commit } ) {
        try {
            const { data: { allPermissions: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_PERMS });
            const nodes = edges.map((d)=> d.node);

            await commit('SET_PERMS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createGroup({ commit }, payload ) {
        try {
            const { data: { createGroup: { group } } }  = await apolloClient
                .mutate({ mutation: CREATE_GROUP, variables: { ...payload }  });
            commit('ADD_GROUP', group);
        } catch (e) {
            console.log(e);
        }
    },
    async updateGroup({ commit }, payload ) {
        try {
            console.log(payload);
            const { data: { updateGroup: { group } } }  = await apolloClient
                .mutate({ mutation: UPDATE_GROUP, variables: { ...payload }  });
            commit('UPDATE_GROUP', group);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteGroup({ commit }, payload ) {
        try {
            const { data: { deleteGroup: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_GROUP, variables: { ...payload }  });
            if (found){
                commit('DELETE_GROUP', payload);
                Vue.$notify({
                    message:
                        `${payload.name } was deleted successfuly`,
                    icon: 'lock_open_variant_outline',
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success',
                });
            }else {
                Vue.$notify({
                    message:
                        `${payload.name} can not be eliminated`,
                    icon: 'add_alert',
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'danger',
                });
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
