<template>
  <v-dialog
      v-model="showForm"
      max-width="400px"
      transition="dialog-top-transition"
      persistent
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          <h4 class="title">{{title}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <div >
              <v-text-field
                  v-model="condicion.nombre"
                  label="Nombre"
              />
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="terciary" dark @click="closed">
            Cancelar
          </v-btn>
          <v-btn v-if="title==='Actualizar Condición'" color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
          <v-btn v-else color="secondary" @click="create" :loading="loading">
            Crear
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CondicionForm",
  props: {
    title: String,
    condicion: {},
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  data: ()=>({
    loading: false,
  }),
  methods: {
    async create() {
      this.loading= true;
      await this.$store.dispatch('createCondicion', this.condicion);
      this.loading= false;
      this.closed();
    },
    async update() {
      this.loading= true;
      await this.$store.dispatch('updateCondicion', this.condicion);
      this.loading= false;
      this.closed();
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>
