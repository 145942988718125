<template>
  <v-dialog
      v-model="showForm"
      max-width="400px"
      transition="dialog-top-transition"
      persistent
      fullscreen
  >
    <v-card width="600">
      <v-toolbar fixed dense dark color="primary">
        <v-toolbar-title>
          <h4 class="title">Asignar Chofer</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <v-container>
             <v-row no-gutters>
              <v-item v-for="(item, index) in traslados" :key="index">
               <v-card class="ma-4" >
               <v-card-title>No: {{item.empleado.numero}}</v-card-title>
               <v-card-text>Nombre y Apellidos: {{item.empleado.nombre + ' ' + item.empleado.apellidoPaterno + ' '
               + item.empleado.apellidoMaterno}}</v-card-text>

                 <v-card-text>Dirección: {{item.empleado.dir}}</v-card-text>
               <v-card-actions>
                 <v-autocomplete
                     v-model="item.chofer"
                     item-text="nombre"
                     item-value="id"
                     :items="choferes"
                     label="Seleccionar Chofer"
                     chips
                     clearable
                     small-chips

                     hint="Chofer"
                     persistent-hint
                 />
               </v-card-actions>
             </v-card>
           </v-item>
             </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="terciary" dark @click="closed">
            Cancelar
          </v-btn>
          <v-btn color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TrasladoForm",
  props: {
    title: String,
    pedidoId: String,
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['choferes']),
  },
  data: ()=>({
    loading: false,
    traslados: [],
  }),
  mounted() {
      this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch('allTraslados');
      this.traslados = this.$store.getters.traslados.filter(t=> t.pedido.id===this.pedidoId);
      await this.$store.dispatch("allChoferes", {estado:1});
      this.loading = false;
    },

    async update() {
      this.loading= true;
      this.traslados.map( async (traslado)=>{
        let sol = {id: traslado.id, chofer:traslado.chofer, empleado: traslado.empleado.id,
            pedido: traslado.pedido.id
        }
        await this.$store.dispatch('updateTraslado', sol);
      });
      let payload = {id:this.pedidoId, estado:2}
      this.$store.commit('CHANGE_ESTADO', payload)
      this.$emit('updated', this.pedidoId);
      this.loading= false;
      this.closed();
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>
