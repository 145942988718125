import gql from 'graphql-tag';

export const ALL_DEPARTAMENTOS = gql`
  query allDepartamentos($empresa: ID) {
    allDepartamentos(empresa:$empresa)
      {
       edges{
        node{
          id
          nombre
          domicilio{
            id
            nombre
            numero
            colonia{
              id
              nombre
              cp
              municipio{
                id
                code
                estado{
                  id
                  code
                  pais{
                    id
                    code
                  }
                }
              }
            }
          }
          empresa{
            id
            nombre
          }
        }
      }
  }
}
`;

export const DEPARTAMENTO = gql`
  query departamento($id: ID!) {
    departamento(id:$id) {
      id
      nombre
      empresa{
        id
        nombre
      }
  }
}
`;
