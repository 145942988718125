import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_CHOFERES } from '../graphql/query/choferQueries';
import { CREATE_CHOFER, UPDATE_CHOFER, DELETE_CHOFER } from '../graphql/mutation/choferMutation';


Vue.use(Vuex);

const state = () => ({
    choferes: [],
    pagination: [],
});

const getters = {
    choferes: state => state.choferes,
};

const mutations = {
    SET_CHOFERES: (state, payload) => state.choferes = payload,
    ADD_CHOFER: (state, payload) => state.choferes.push(payload),
    DELETE_CHOFER: (state, payload) => state.choferes = state.choferes.filter(el => el.id !== payload.id),
    UPDATE_CHOFER: (state, payload) => state.choferes = state.choferes.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allChoferes({ commit }, payload ) {
        try {
            const { data: { allChoferes: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_CHOFERES, variables: {...payload} });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_CHOFERES', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createChofer({ commit }, payload ) {
        try {
            const { data: { createChofer: { chofer } } }  = await apolloClient
                .mutate({ mutation: CREATE_CHOFER, variables: { ...payload }  });
            commit('ADD_CHOFER', chofer);
        } catch (e) {
            console.log(e);
        }
    },
    async updateChofer({ commit }, payload ) {
        try {
            const { data: { updateChofer: { chofer } } }  = await apolloClient
                .mutate({ mutation: UPDATE_CHOFER, variables: { ...payload }  });
            commit('UPDATE_CHOFER', chofer);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteChofer({ commit }, payload ) {
        try {
            const { data: { deleteChofer: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_CHOFER, variables: { ...payload }  });
            if (found){
                commit('DELETE_CHOFER', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
