import gql from 'graphql-tag';

export const ALL_EMPLEADOS = gql`
  query allEmpleados($first: Int $after: String) {
    allEmpleados(first:$first after:$after)
    {
    pageInfo{
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
        edges{
        node{
            id
            numero
            nombre
            apellidoPaterno
            apellidoMaterno
            celular
            dir
            planta{
              id
              nombre
            }
            departamento{
              id
              nombre
            }
    
        }
    }
    }
}
`;

export const FILTER_EMPLEADOS = gql`
  query allEmpleados($range: String $in: String) {
    allEmpleados(numero_Range: $range numero_In: $in){
      edges{
        node{
          id
          nombre
          apellidoPaterno
          apellidoMaterno
        }
      }
    }
}
`;

export const EMPLEADO = gql`
  query empleado($id: ID!) {
    empleado(id:$id) {
      id
      numero
      nombre
      apellidoPaterno
      apellidoMaterno
      celular
      dir
  }
}
`;


