import gql from 'graphql-tag';

export const ALL_DOMICILIOS = gql`
  query allDomicilios {
    allDomicilios
      {
       edges{
        node{
          id
          nombre
          numero
          colonia{
           id
           nombre
           cp
          }
        }
      }
  }
}
`;

export const DOMICILIO = gql`
  query domicilio($id: ID!) {
    domicilio(id:$id) {
      id
      nombre
      numero
      colonia{
        id
        nombre
      }
  }
}
`;
