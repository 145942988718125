import gql from 'graphql-tag';

export const CREATE_EMPRESACOND = gql`
mutation CreateEmpresaCondicion($empresa: ID! $condicion: ID! $opcion: ID){
 createEmpresaCondicion(input: { empresa:$empresa condicion: $condicion opcion: $opcion}) {
  empresaCondicion{ 
   id
      empresa{
        id
        nombre
      }
      condicion{
        id
        nombre
      }
      opcion{
        id
        nombre
      }
  }
}
}
`;

export const UPDATE_EMPRESACOND = gql`
mutation UpdateCondicion($id: ID! $empresa: ID! $condicion: ID! $opcion: ID){
 updateCondicion(id:$id input: { empresa:$empresa condicion: $condicion opcion: $opcion }) {
  empresaCondicion{
    id
      empresa{
        id
        nombre
      }
      condicion{
        id
        nombre
      }
      opcion{
        id
        nombre
      }
  }
}
}
`;

export const DELETE_EMPRESACOND = gql`
mutation DeleteEmpresaCondicion($id: ID!){
deleteEmpresaCondicion(id:$id) {
  found
}
}
`;
