import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_COLONIAS } from '../graphql/query/coloniaQueries';
import { CREATE_COLONIA, UPDATE_COLONIA, DELETE_COLONIA } from '../graphql/mutation/coloniaMutation';


Vue.use(Vuex);

const state = () => ({
    colonias: [],
    pagination: [],
});

const getters = {
    colonias: state => state.colonias,
};

const mutations = {
    SET_COLONIAS: (state, payload) => state.colonias = payload,
    ADD_COLONIA: (state, payload) => state.colonias.push(payload),
    DELETE_COLONIA: (state, payload) => state.colonias = state.colonias.filter(el => el.id !== payload.id),
    UPDATE_COLONIA: (state, payload) => state.colonias = state.colonias.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allColonias({ commit } ) {
        try {
            const { data: { allColonias: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_COLONIAS });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_COLONIAS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createColonia({ commit }, payload ) {
        try {
            const { data: { createColonia: { colonia } } }  = await apolloClient
                .mutate({ mutation: CREATE_COLONIA, variables: { ...payload }  });
            commit('ADD_COLONIA', colonia);
        } catch (e) {
            console.log(e);
        }
    },
    async updateColonia({ commit }, payload ) {
        try {
            const { data: { updateColonia: { colonia } } }  = await apolloClient
                .mutate({ mutation: UPDATE_COLONIA, variables: { ...payload }  });
            commit('UPDATE_COLONIA', colonia);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteColonia({ commit }, payload ) {
        try {
            const { data: { deleteColonia: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_COLONIA, variables: { ...payload }  });
            if (found){
                commit('DELETE_COLONIA', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
