<template>
  <div>
    <v-card>
      <v-toolbar dark color="primary" shaped>
        <v-toolbar-title>Domicilios</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="mr-16">
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              shaped
              single-line
              hide-details
          ></v-text-field>
        </div>
        <v-btn
            color="secondary"
            absolute
            top
            right
            fab
            @click="toogleForm"
            :disabled="showForm"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
          :headers="header"
          :items="domicilios"
          :search="search"
          :loading="loading"
          loading-text="Cargando... Espere porfavor"
          @change="loadData"
          no-data-text="No existen Domicilios registradas en el sistema."
          :item-key="domicilios.id"

      >
        <template v-slot:body="props" >
          <tbody name="slide" is="transition-group" mode="out-in" appear>
          <template >
            <tr v-for="(item, index) in props.items" :key="item.id">
              <td>{{index+1}}</td>
              <td>{{item.nombre}}</td>
              <td>{{item.numero}}</td>
              <td>{{item.colonia.nombre}}</td>
              <td>
                <TableAction :item="item" @update="toogleForm" @delaction="deleteItem"/>
              </td>
            </tr>
          </template>
          </tbody>
        </template>

      </v-data-table>
    </v-card>
    <template>
      <domicilio-form v-if="showForm" :title="title" :show-form="showForm"
                    :domicilio="editItem" @close="toogleForm" />
    </template>
    <template>
      <v-dialog persistent max-width="200" transition="dialog-top-transition" v-if="menuLocked" v-model="menuLocked">
        <v-card>
          <v-card-title>Delete</v-card-title>
          <v-card-text>Seguro que desea eliminar {{itemToDelete.nombre}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="terciary"
                text
                @click="deleteItemCancel"
                icon
            >
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
                color="secondary"
                text
                @click="deleteItemConfirm"
                icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

  </div>
</template>

<script>
import TableAction from "../../components/TableAction";
import DomicilioForm from "../../components/Domicilio/DomicilioForm";
import {mapGetters} from "vuex";

export default {
  name: "DomicilioList",
  components: {TableAction, DomicilioForm},
  data:()=>({
    loading: false,
    showForm: false,
    search: '',
    title:'',
    defaultEditItem: null,
    editItem: null,
    itemToDelete: null,
    menuLocked: false,
    deleteConfirmId: '',
    header: [
      { text: 'No', value: 'num', sortable: false },
      { text: 'Nombre', value: 'nombre' },
      { text: 'Número', value: 'numero' },
      { text: 'Colonia', value: 'colonia.nombre' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
  }),
  computed: {
    ...mapGetters(['domicilios']),
  },
  mounted() {
    if(this.domicilios.length<=0){
      this.loadData()
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("allDomicilios");
      this.loading = false;
    },
    toogleForm(item){
      this.domicilios.indexOf(item)=== -1 ? (this.editItem= {}, this.title='Nueva Domicilio') :
          (this.editItem = {...item}, this.title='Actualizar Domicilio');
      this.showForm = ! this.showForm;
    },

    deleteItem(item) {
      this.itemToDelete = item
      this.menuLocked = true

    },

    async deleteItemConfirm() {
      if (this.itemToDelete !== null) {
        await this.$store.dispatch("deleteDomicilio", this.itemToDelete);
        this.menuLocked = false;
        this.itemToDelete = null;

      }
    },

    deleteItemCancel() {
      this.menuLocked = false
      this.itemToDelete = null
    },
  },
}
</script>

<style scoped>

</style>
