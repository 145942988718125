import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import {ALL_PEDIDOS, PEDIDO} from '../graphql/query/pedidoQueries';
import { CREATE_PEDIDO, UPDATE_PEDIDO, DELETE_PEDIDO } from '../graphql/mutation/pedidoMutation';


Vue.use(Vuex);

const state = () => ({
    pedidos: [],
    pagination: [],
});

const getters = {
    pedidos: state => state.pedidos,
};

const mutations = {
    SET_PEDIDOS: (state, payload) => state.pedidos = payload,
    ADD_PEDIDO: (state, payload) => state.pedidos.push(payload),
    DELETE_PEDIDO: (state, payload) => state.pedidos = state.pedidos.filter(el => el.id !== payload.id),
    UPDATE_PEDIDO: (state, payload) => state.pedidos = state.pedidos.map(el => (el.id === payload.id ? payload : el)),
    CHANGE_ESTADO: (state, payload) => state.pedidos = state.pedidos
        .map(el => (el.id === payload.id ? {...el, estado: payload.estado}: el)),
};

const actions = {
    async allPedidos({ commit } ) {
        try {
            const { data: { allPedidos: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_PEDIDOS });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_PEDIDOS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createPedido({ commit }, payload ) {
        try {
            const { data: { createPedido: { pedido } } }  = await apolloClient
                .mutate({ mutation: CREATE_PEDIDO, variables: { ...payload }  });
            commit('ADD_PEDIDO', pedido);
            return pedido.id;
        } catch (e) {
            console.log(e);
        }
    },
    async updateEst({commit}, payload){
        try {
            const {data: { pedido }} = await apolloClient
                .mutate({ mutation: PEDIDO, variables: {...payload}});
            if (pedido.estado !==1){
                console.log(pedido.estado)
                commit('UPDATE_PEDIDO', pedido)
            }
        } catch (e) {
            console.log(e)
        }
    },
    async updatePedido({ commit }, payload ) {
        try {
            const { data: { updatePedido: { pedido } } }  = await apolloClient
                .mutate({ mutation: UPDATE_PEDIDO, variables: { ...payload }  });
            commit('UPDATE_PEDIDO', pedido);
        } catch (e) {
            console.log(e);
        }
    },
    async deletePedido({ commit }, payload ) {
        try {
            const { data: { deletePedido: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_PEDIDO, variables: { ...payload }  });
            if (found){
                commit('DELETE_PEDIDO', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
