import gql from 'graphql-tag';

export const CREATE_TIPOPEDIDO = gql`
mutation CreateTipoPedido($nombre: String! $origen: String! $destino: String! $isMasive: Boolean ){
 createTipoPedido(input: { nombre:$nombre origen:$origen destino:$destino isMasive:$isMasive }) {
  tipoPedido{ 
    id
    nombre
    origen
    destino
    isMasive
  }
}
}
`;

export const UPDATE_TIPOPEDIDO = gql`
mutation UpdateTipoPedido($id: ID! $nombre: String! $origen: String! $destino: String! $isMasive: Boolean){
 updateTipoPedido(id:$id input: { nombre:$nombre origen:$origen destino:$destino isMasive:$isMasive}) {
  tipoPedido{ 
    id
    nombre
    origen
    destino 
    isMasive
  }
}
}
`;

export const DELETE_TIPOPEDIDO = gql`
mutation DeleteTipoPedido($id: ID!){
deleteTipoPedido(id:$id) {
  found
}
}
`;
