<template>
  <v-dialog
      v-model="showForm"
      max-width="400px"
      transition="dialog-top-transition"
      persistent
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          <h4 class="title">{{title}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <div >
              <v-text-field
                  v-model="taxi.modelo"
                  label="Modelo"
              />
              <v-text-field
                  v-model="taxi.marca"
                  label="Marca"
              />
              <v-text-field
                  v-model="taxi.matricula"
                  label="Matrícula"
              />
              <v-text-field type="number"
                  v-model="taxi.cantPasajeros"
                  label="Cantidad de pasajeros"
              />
              <v-text-field
                  v-model="taxi.color"
                  label="Color del Auto"
              />
              <v-text-field
                  v-model="taxi.agenciaSeguro"
                  label="Agencia Aseguradora"
              />
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="taxi.caducidadSeguro"
                      label="Caducidad del seguro"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="taxi.caducidadSeguro"
                    @input="menu = false"
                    locale="es-es"
                >
                </v-date-picker>
              </v-menu>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="terciary" @click="closed">
            Cancelar
          </v-btn>
          <v-btn v-if="title==='Actualizar Auto'" color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
          <v-btn v-else color="secondary" @click="create" :loading="loading">
            Crear
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TaxiForm",
  props: {
    title: String,
    taxi: {},
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  data: ()=>({
    loading: false,
    menu: false,
  }),
  methods: {
    async create() {
      this.loading= true;
      await this.$store.dispatch('createTaxi', this.taxi);
      this.loading= false;
      this.closed();
    },
    async update() {
      this.loading= true;
      await this.$store.dispatch('updateTaxi', this.taxi);
      this.loading= false;
      this.closed();
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>


