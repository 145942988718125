import gql from 'graphql-tag';

export const CREATE_DEPARTAMENTO = gql`
mutation CreateDepartamento($domicilio: ID! $nombre: String! $empresa: ID!){
 createDepartamento(input: { domicilio:$domicilio nombre:$nombre empresa:$empresa}) {
  departamento{ 
   id
   nombre
    domicilio{
            id
            nombre
            numero
            colonia{
              id
              nombre
              cp
              municipio{
                id
                code
                estado{
                  id
                  code
                  pais{
                    id
                    code
                  }
                }
              }
            }
          }
          empresa{
            id
            nombre
          }
  }
}
}
`;

export const UPDATE_DEPARTAMENTO = gql`
mutation UpdateDepartamento($id: ID! $domicilio: ID! $nombre: String! $empresa: ID!){
 updateDepartamento(id:$id input: { domicilio:$domicilio, nombre:$nombre empresa:$empresa}) {
  departamento{
    id
    nombre
     domicilio{
            id
            nombre
            numero
            colonia{
              id
              nombre
              cp
              municipio{
                id
                code
                estado{
                  id
                  code
                  pais{
                    id
                    code
                  }
                }
              }
            }
          }
          empresa{
            id
            nombre
          }
  }
}
}
`;

export const DELETE_DEPARTAMENTO = gql`
mutation DeleteDepartamento($id: ID!){
deleteDepartamento(id:$id) {
  found
}
}
`;
