<template>
  <div class="layout">
    <v-row align-content="center" align-content-sm="center" >
      <v-card class="ml-13" width="300" shaped hover>

          <v-avatar class="mt-n10 ml-12" size="200">
            <v-img :src="ima"></v-img>
          </v-avatar>

          <v-card-title>User Card</v-card-title>
          <v-card-subtitle>{{ user.username }}</v-card-subtitle>
          <v-card-text>
            <h6 class="category text-gray">{{ user.firstName }}</h6>
            <h6 class="category text-gray">{{ user.lastName }}</h6>
          </v-card-text>
        </v-card>
      <v-card class="ml-13 mt-sm-4 mt-xs-4 mt-md-0" width="400" height="500" max-width="800"  max-height="600">
          <v-card dark color="primary" hover>
           <v-card-title>
            <h4 class="title">Editar Perfil</h4>
           </v-card-title>
          </v-card>
          <v-form>
              <v-card-text>
                <div class="md-layout">
                  <div>
                    <v-text-field v-model="user.username" label="Usuario">
                    </v-text-field>
                  </div>
                  <div >
                    <v-text-field v-model="user.email" label="Email" disabled>
                    </v-text-field>
                  </div>
                  <div>
                    <v-text-field v-model="user.firstName" label="Nombre">
                    </v-text-field>
                  </div>
                  <div>
                    <v-text-field v-model="user.lastName" label="Apellidos">
                    </v-text-field>
                  </div>
                  <div>
                    <v-file-input shaped label="Avatar" accept=".jpg" v-model="user.image"/>
                  </div>
                  <div class="text-right">
                    <v-btn color="secondary" @click="update">
                      Actualizar
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
          </v-form>
        </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "UserProfile",
  data () {
    return {
      user: this.$store.getters.user,
      ima: require('../../assets/marc.jpg'),
    }
  },
  methods: {
    update() {
      this.$store.dispatch('updateUser', this.user);
    },
  },
}
</script>

<style scoped>

</style>
