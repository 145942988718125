import gql from 'graphql-tag';

export const CREATE_TRASLADO = gql`
mutation CreateTraslado($pedido: ID! $empleado: ID $origen: ID  $destino: ID){
 createTraslado(input: { pedido:$pedido empleado:$empleado origen:$origen destino:$destino}) {
  traslado{ 
    pedido{
        id
        fecha
        hora
      }
      empleado{
        id
        numero
        nombre
      }
      origen{
        id
      }
      destino{
        id
      }
      chofer{
        nombre
      }
  }

}
}
`;

export const UPDATE_TRASLADO = gql`
mutation UpdateTraslado($id: ID! $pedido: ID! $empleado: ID $origen: ID  $destino: ID $chofer: ID){
 updateTraslado(id:$id input: { pedido:$pedido empleado:$empleado origen:$origen destino:$destino chofer:$chofer}) {
  traslado{ 
    pedido{
        id
        fecha
        hora
      }
      empleado{
        id
        numero
        nombre
      }
      origen{
        id
      }
      destino{
        id
      }
      chofer{
        nombre
      }
  }

}
}
`;

export const DELETE_TRASLADO = gql`
mutation DeleteTraslado($id: ID!){
deleteTraslado(id:$id) {
  found
}
}
`;
