<template>
  <v-dialog
      v-model="showForm"
      max-width="400px"
      transition="dialog-top-transition"
      persistent
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          <h4 class="title">{{title}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <div >
              <v-text-field
                  v-model="estado.nombre"
                  label="Estado"
              />
              <v-text-field
                  v-model="estado.code"
                  label="Código"
              />
              <v-select
                  v-model="pais"
                  :items="countries"
                  item-text="nombre"
                  item-value="id"
                  label="Selecciona el país"
                  chips
                  hint="País"
                  persistent-hint
              ></v-select>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="terciary" @click="closed">
            Cancelar
          </v-btn>
          <v-btn v-if="title==='Actualizar Estado'" color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
          <v-btn v-else color="secondary" @click="create" :loading="loading">
            Crear
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "EstadoForm",
  props: {
    title: String,
    estado: {},
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['countries']),
  },
  data: ()=>({
    loading: false,
    pais:{},
  }),
  mounted() {
    this.pais = this.estado.pais ? this.estado.pais.id : null ;
  },
  methods: {
    async create() {
      this.loading= true;
      this.estado.pais = this.pais;
      await this.$store.dispatch('createEstado', this.estado);
      this.loading= false;
      this.closed();
    },
    async update() {
      this.loading= true;
      this.estado.pais = this.pais;
      await this.$store.dispatch('updateEstado', this.estado);
      this.loading= false;
      this.closed();
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>
