import gql from 'graphql-tag';

export const ALL_COLONIAS = gql`
  query allColonias {
    allColonias
      {
       edges{
        node{
          id
          nombre
          cp
          municipio{
            id
            nombre
        
          }
        }
      }
  }
}
`;

export const COLONIA = gql`
  query colonia($id: ID!) {
    colonia(id:$id) {
      id
      nombre
      cp
  }
}
`;
