<template>
  <v-dialog
      v-model="showForm"
      max-width="400px"
      transition="dialog-top-transition"
      persistent
  >
    <v-card width="600">
      <v-toolbar fixed dense dark color="primary">
        <v-toolbar-title>
          <h4 class="title">{{title}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs
              v-model="tab"
              align-with-title
          >
            <v-tabs-slider color="terciary"></v-tabs-slider>

            <v-tab
                v-for="item in items"
                :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <div >
                  <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="pedido.fecha"
                          label="Fecha"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="fechaFieldRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="pedido.fecha"
                        @input="menu = false"
                    ></v-date-picker>
                  </v-menu>

                  <v-menu
                      ref="menu"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="pedido.hora"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="pedido.hora"
                          label="Hora"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="horaFieldRules"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu1"
                        v-model="pedido.hora"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menu.save(pedido.hora)"
                    ></v-time-picker>
                  </v-menu>
                  <v-checkbox v-model="repetir" v-if="this.start<6" label="Repetir"/>
                  <v-item-group multiple v-show="repetir" v-model="selectedDias">
                    <v-item
                        v-for="(n, index) in dias.slice(this.start+1 , 7)"
                        :key="n"
                        v-slot="{ active, toggle}"
                        @change="addDay(n,index+1)"
                    >
                      <v-chip
                          active-class="secondary"
                          :input-value="active"
                          @click="toggle"
                      >
                        {{ n }}
                      </v-chip>
                    </v-item>
                  </v-item-group>

                  <v-autocomplete
                      v-model="tipoPedido"
                      :items="tipoPedidos"
                      item-text="nombre"
                      item-value="id"
                      label="Tipo de pedido"
                      chips
                      prepend-icon="mdi-format-list-checks"
                  ></v-autocomplete>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div >
                  <v-select
                      v-model="selection"
                      :items="sel"
                    ></v-select>
                  <v-text-field
                        append-icon="mdi-account-plus"
                        v-model="lista"
                        class="mx-4"
                        text
                        @click:append="buscar"
                        @keypress.enter="buscar"
                        hide-details
                        label="Buscar"
                        prepend-inner-icon="mdi-magnify"
                        solo-inverted
                    ></v-text-field>
                  <v-divider></v-divider>
                  <v-list class="v-list" two-line>
                    <v-list-item-group
                        v-model="empleados"
                        active-class="pink--text"
                        multiple
                    >
                      <template v-for="(item, index) in empleados">
                        <v-list-item :key="item.id">
                            <v-list-item-content>
                              <v-list-item-title v-text="item.nombre"></v-list-item-title>

                              <v-list-item-subtitle
                                  class="text--primary"
                                  v-text="item.apellidoPaterno"
                              ></v-list-item-subtitle>

                              <v-list-item-subtitle v-text="item.apellidoMaterno"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-btn icon @click="quitEmp(item)">
                                <v-icon color="terciary">mdi-delete</v-icon>
                              </v-btn>
                            </v-list-item-action>
                        </v-list-item>

                        <v-divider
                            v-if="index < items.length - 1"
                            :key="index"
                        ></v-divider>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-tab-item>
            </v-tabs-items>

          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="terciary" dark @click="closed">
            Cancelar
          </v-btn>
          <v-btn v-if="title==='Actualizar Solicitud'" color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
          <v-btn v-else color="secondary" @click="create" :loading="loading">
            Crear
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "PedidoForm",
  props: {
    title: String,
    start: Number,
    pedido: {},
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['tipoPedidos','filterEmpleados']),
  },
  data: ()=>({
    loading: false,
    menu: false,
    repetir: false,
    dias: ['DOM', 'LUN','MAR','MIE', 'JUE', 'VIE','SAB'],
    selectedDias:[],
    selectedFechas:[],
    menu1: false,
    lista: "",
    listaEmpleado: {},
    sel: ["Rango", "Lista"],
    selection: 'Rango',
    tab: null,
    items: [
      'Solicitud', 'Colaboradores',
    ],
    empleados: [],
    tipoPedido: {},
    fechaFieldRules: [(v) => !!v || 'Debe proporcionar una fecha.'],
    horaFieldRules: [(v) => !!v || 'Debe proporcionar una hora.'],
  }),
  mounted() {
    this.tipoPedido = this.pedido.tipoPedido ? this.pedido.tipoPedido.id : null ;
    this.empleados = this.pedido.pedidoTraslado ? this.pedido.pedidoTraslado.edges.map(d=> d.node.empleado) : [] ;
    console.log(this.tipoPedidos.length)
    if(this.tipoPedidos.length<=0){
      this.loadData()
    }
  },
  methods: {
    addDay(n,index) {

     let cond = this.selectedDias.find(e=> e === n)
      let date = this.addDate(index);
      if(cond){
        this.selectedDias = this.selectedDias.filter(el => el !== n)
        this.selectedFechas = this.selectedFechas.filter(el => el !== date)
      }else{
        this.selectedDias.push(n);
        this.selectedFechas.push(date)
      }
    },
    addDate(index) {
      let date = this.pedido.fecha.split('-');
      let year = parseInt(date[0]);
      let mes = parseInt(date[1]);
      let day = parseInt(date[2]);
      let sig = day+index;
      sig = sig <=9 ? '0'+sig : sig;
      let fecha = year +'-'+mes+'-'+sig
      return fecha;
    },
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("allTipoPedidos");
      this.loading = false;
    },
    async buscar() {
      this.loading= true;
       //TODO AUN no se puede hacer merge de los elementos
      if (this.selection !== "" && this.lista !== ""){
        let payload = this.selection === "Rango" ? { range: this.lista } : { in: this.lista };
        await this.$store.dispatch('filterEmpleados', payload);
        if(this.empleados ===[]){
          this.empleados.push(...this.filterEmpleados);
        }else{
          this.empleados = [...new Set([...this.empleados, ...this.filterEmpleados])];
        }
        console.log(this.empleados)
        await this.$store.dispatch('cleanFempleados');
      }
      this.loading= false;
    },
    quitEmp(item) {
      this.$store.dispatch('qEmpleado', item)
    },
    async create() {
      this.loading= true;
      this.pedido.tipoPedido = this.tipoPedido;

      let id = await this.$store.dispatch('createPedido', this.pedido);
      await this.createTraslado(id);
      if (this.repetir && this.selectedFechas.length>=1){
        for (let i=0; i<this.selectedFechas.length;i++){
          let pedido = {...this.pedido, fecha:this.selectedFechas[i]}
          let id = await this.$store.dispatch('createPedido', pedido)
          await this.createTraslado(id);
        }
      }

      this.loading= false;
      this.$emit('updated');
      this.closed();
    },
    async createTraslado(id) {
      if(id !== ''){
        this.empleados.map((item)=>{let payload = {pedido: id, empleado: item.id}
          this.$store.dispatch('createTraslado', payload) });
      }
    },
    async update() {
      this.loading= true;
      this.pedido.tipoPedido = this.tipoPedido;
      await this.$store.dispatch('updatePedido', this.pedido);
      this.$emit('updated');
      this.loading= false;
      this.closed();
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>
.v-list{
  height: 200px;
  overflow-y: auto;
  align-items: baseline;
}
</style>
