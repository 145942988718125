import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_COUNTRIES } from '../graphql/query/countryQueries';
import { CREATE_COUNTRY, UPDATE_COUNTRY, DELETE_COUNTRY } from '../graphql/mutation/countryMutation';


Vue.use(Vuex);

const state = () => ({
    countries: [],
    pagination: [],
});

const getters = {
    countries: state => state.countries,
};

const mutations = {
    SET_COUNTRIES: (state, payload) => state.countries = payload,
    ADD_COUNTRY: (state, payload) => state.countries.push(payload),
    DELETE_COUNTRY: (state, payload) => state.countries = state.countries.filter(el => el.id !== payload.id),
    UPDATE_COUNTRY: (state, payload) => state.countries = state.countries.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allCountries({ commit } ) {
        try {
            const { data: { allPaises: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_COUNTRIES });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_COUNTRIES', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createCountry({ commit }, payload ) {
        try {
            const { data: { createPais: { pais } } }  = await apolloClient
                .mutate({ mutation: CREATE_COUNTRY, variables: { ...payload }  });
            commit('ADD_COUNTRY', pais);
        } catch (e) {
            console.log(e);
        }
    },
    async updateCountry({ commit }, payload ) {
        try {
            const { data: { updatePais: { pais } } }  = await apolloClient
                .mutate({ mutation: UPDATE_COUNTRY, variables: { ...payload }  });
            commit('UPDATE_COUNTRY', pais);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteCountry({ commit }, payload ) {
        try {
            const { data: { deletePais: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_COUNTRY, variables: { ...payload }  });
            if (found){
                commit('DELETE_COUNTRY', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
