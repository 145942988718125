import gql from 'graphql-tag';

export const ALL_MUNICIPIOS = gql`
  query allMunicipios {
    allMunicipios {
    edges{
      node{
        id
        nombre
        code
        estado{
            id
            nombre
      }
      }
    }
  }
}
`;

export const MUNICIPIO = gql`
  query municipio($id: ID!) {
    municipio(id:$id) {
      id
      nombre
      code
  }
}
`;
