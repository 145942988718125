<template>
  <v-dialog
      v-model="showForm"
      max-width="400px"
      transition="dialog-top-transition"
      persistent
  >
    <v-card>
      <v-toolbar fixed dark color="primary">
        <v-toolbar-title>
          <h4 class="title">{{title}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <div >
              <v-autocomplete
                  v-model="user"
                  :items="users"
                  item-text="username"
                  item-value="id"
                  label="Seleccionar Usuario"
                  chips
                  clearable
                  deletable-chips
                  small-chips

                  hint="Usuario"
                  persistent-hint

              >
              </v-autocomplete>
              <v-autocomplete
                  v-model="empleado"
                  :items="empleados"
                  item-text="nombre"
                  item-value="id"
                  label="Selecciona el Colaborador con Rol Cliente"
                  chips
                  clearable
                  small-chips
                  hint="Supervisor Cliente"
                  persistent-hint
              />
              <v-autocomplete
                  v-model="chofer"
                  :items="choferes"
                  item-text="nombre"
                  item-value="id"
                  label="Seleccionar Chofer"
                  chips
                  clearable
                  deletable-chips
                  small-chips
                  hint="Chofer"
                  persistent-hint

              >
              </v-autocomplete>
              <v-autocomplete
                  v-model="guardia"
                  :items="guardias"
                  item-text="nombre"
                  item-value="id"
                  label="Seleccionar Guardia"
                  chips
                  clearable
                  deletable-chips
                  small-chips
                  hint="Guardia"
                  persistent-hint

              >
              </v-autocomplete>
              <v-autocomplete
                  v-model="empresa"
                  :items="empresas"
                  item-text="nombre"
                  item-value="id"
                  label="Selecciona la Empresa"
                  chips
                  hint="Empresa"
                  persistent-hint
              ></v-autocomplete>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="terciary" dark @click="closed">
            Cancelar
          </v-btn>
          <v-btn v-if="title==='Actualizar Asignación'" color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
          <v-btn v-else color="secondary" @click="create" :loading="loading">
            Crear
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AsignacionForm",
  props: {
    title: String,
    uempresa: {},
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['users','guardias', 'choferes', 'empresas', 'empleados'])
  },
  data: ()=>({
    loading: false,
    seguridad: false,
    supervisor: false,

    cliente: false,
    user: null,
    empresa: null,
    guardia: null,
    chofer: null,
    empleado: null,
  }),
  mounted() {
    this.user = this.uempresa.user ? this.uempresa.user.id : null;
    this.empresa = this.uempresa.empresa ? this.uempresa.empresa.id : null;
    this.guardia = this.uempresa.guardia ? this.uempresa.guardia.id : null;
    this.chofer = this.uempresa.chofer ? this.uempresa.chofer.id : null;
    this.empleado = this.uempresa.empleado ? this.uempresa.empleado.id : null;
    if (this.users.length<=0 || this.guardias.length<=0 || this.choferes.length<=0
        || this.empresas.length<=0 || this.empleados.length<=0){
      this.loadData()
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("allUsers");
      await this.$store.dispatch("allGuardias");
      await this.$store.dispatch("allChoferes");
      await this.$store.dispatch("allEmpresas");
      await this.$store.dispatch("allEmpleados");
      this.loading = false;
    },
    async create() {
      this.loading= true;
      this.uempresa.user=this.user;
      this.uempresa.chofer=this.chofer;
      this.uempresa.empresa=this.empresa;
      this.uempresa.guardia=this.guardia;
      this.uempresa.empleado=this.empleado;
      await this.$store.dispatch('createUsuarioEmpresa', this.uempresa);
      this.loading= false;
      this.closed();
    },
    async update() {
      this.loading= true;
      this.uempresa.user=this.user;
      this.uempresa.chofer=this.chofer;
      this.uempresa.empresa=this.empresa;
      this.uempresa.guardia=this.guardia;
      this.uempresa.empleado=this.empleado;
      await this.$store.dispatch('updateUsuarioEmpresa', this.uempresa);
      this.loading= false;
      this.closed();
    },
    async getRol() {
      //TODO terminar este metodo que no está desplegando los componentes como va
      if (this.user){
        const user = this.users.filter(e => e.id === this.user ? e: null);
        if (user !== null){
          const roll = user[0].groups.edges.map(n => n.node.name);
          switch (roll) {
            case 'seguridad': {
              await this.$store.dispatch("allGuardias");
              this.seguridad = true;
              break;
            }
            case 'chofer': {
              this.chofer = true;
              await this.$store.dispatch("allChoferes");
              break;
            }
            case 'supervisor': {
              this.supervisor = true;
              await this.$store.dispatch("allEmpresas");
              break;
            }
            case 'cliente': {
              this.cliente = true;
              break;
            }
          }
        }
      }
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>
