<template>
  <v-dialog
      v-model="showForm"
      max-width="400px"
      transition="dialog-top-transition"
      persistent
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          <h4 class="title">{{title}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <div >
              <v-select
                  v-model="empresa"
                  :items="empresas"
                  item-text="nombre"
                  item-value="id"
                  label="Selecciona la empresa"
                  chips
                  hint="Empresa"
                  persistent-hint
              ></v-select>
              <v-select
                  v-model="condicion"
                  :items="condiciones"
                  item-text="nombre"
                  item-value="id"
                  label="Selecciona la Condición"
                  chips
                  hint="Condición"
                  persistent-hint
              ></v-select>
              <v-select
                  v-model="opcion"
                  :items="opciones"
                  item-text="nombre"
                  item-value="id"
                  label="Selecciona la Opción"
                  chips
                  hint="Opción"
                  persistent-hint
              ></v-select>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="terciary" dark @click="closed">
            Cancelar
          </v-btn>
          <v-btn v-if="title==='Actualizar Empresa-Condición'" color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
          <v-btn v-else color="secondary" @click="create" :loading="loading">
            Crear
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "EmpresaCondicionForm",
  props: {
    title: String,
    econdicion: {},
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  data: ()=>({
    loading: false,
    condicion: {},
    opcion: {},
    empresa: {},
  }),
  computed: {
    ...mapGetters(['empresas', 'condiciones', 'opciones']),
  },
  mounted() {
    this.condicion = this.econdicion.condicion ? this.econdicion.condicion.id : null ;
    this.opcion = this.econdicion.opcion ? this.econdicion.opcion.id : null ;
    this.empresa = this.econdicion.empresa ? this.econdicion.empresa.id : null ;
  },
  methods: {
    async create() {
      this.loading= true;
      this.econdicion.empresa = this.empresa;
      this.econdicion.condicion = this.condicion;
      this.econdicion.opcion = this.opcion;
      await this.$store.dispatch('createEmpresaCondicion', this.econdicion);
      this.loading= false;
      this.closed();
    },
    async update() {
      this.loading= true;
      this.econdicion.empresa = this.empresa;
      this.econdicion.condicion = this.condicion;
      this.econdicion.opcion = this.opcion;
      await this.$store.dispatch('updateEmpresaCondicion', this.econdicion);
      this.loading= false;
      this.closed();
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>
