import gql from 'graphql-tag';

export const ALL_EMPRESAS = gql`
  query allEmpresas {
    allEmpresas
      {
       edges{
        node{
          id
          nombre
          domicilio{
            id
            nombre
            numero
        }
        }
      }
  }
}
`;

export const EMPRESA = gql`
  query empresa($id: ID!) {
    empresa(id:$id) {
      id
      nombre
  }
}
`;
