import gql from 'graphql-tag';

export const CREATE_COUNTRY = gql`
mutation CreatePais($nombre: String! $code: String!){
 createPais(input: { nombre:$nombre code:$code}) {
  pais{ 
   id
   nombre
   code
  }
}
}
`;

export const UPDATE_COUNTRY = gql`
mutation UpdatePais($id: ID! $nombre: String! $code:String!){
 updatePais(id:$id input: { nombre:$nombre, code:$code }) {
  pais{
    id
    nombre
    code
  }
}
}
`;

export const DELETE_COUNTRY = gql`
mutation DeletePais($id: ID!){
deletePais(id:$id) {
  found
}
}
`;
