import gql from 'graphql-tag';

export const ALL_EMPRESA_COND =gql`
  query allEmpresaCondiciones{
   allEmpresaCondiciones
      {
       edges{
        node{
          id
      empresa{
        id
        nombre
      }
      condicion{
        id
        nombre
      }
      opcion{
        id
        nombre
      }
        }
      }
      }
  }
`;
