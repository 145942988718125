import gql from 'graphql-tag';

export const LOGGED_IN_USER = gql`
  query me2 {
    me2 {
      id,
      username,
      firstName,
      lastName,
      email,
      image,
      isSuperuser
      isStaff
    groups{
      edges{
        node{
          id
          name
        }
      }
    }
    }
}
`;
export const All_USER = gql`
  query{
  allUsers{
    edges{
      node{
        id,
        username,
        email,
        firstName,
        lastName
        isActive
        isSuperuser
        isStaff
        groups{
          edges{
            node{
              id
              name
            }
          }
        }
      }
    }
  }
}
`;
