import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_OPCIONES } from '../graphql/query/opcionQueries';
import { CREATE_OPCION, UPDATE_OPCION, DELETE_OPCION } from '../graphql/mutation/opcionMutation';


Vue.use(Vuex);

const state = () => ({
    opciones: [],
    pagination: [],
});

const getters = {
    opciones: state => state.opciones,
};

const mutations = {
    SET_OPCIONES: (state, payload) => state.opciones = payload,
    ADD_OPCION: (state, payload) => state.opciones.push(payload),
    DELETE_OPCION: (state, payload) => state.opciones = state.opciones.filter(el => el.id !== payload.id),
    UPDATE_OPCION: (state, payload) => state.opciones = state.opciones.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allOpciones({ commit } ) {
        try {
            const { data: { allOpciones: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_OPCIONES });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_OPCIONES', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createOpcion({ commit }, payload ) {
        try {
            const { data: { createOpcion: { opcion } } }  = await apolloClient
                .mutate({ mutation: CREATE_OPCION, variables: { ...payload }  });
            commit('ADD_OPCION', opcion);
        } catch (e) {
            console.log(e);
        }
    },
    async updateOpcion({ commit }, payload ) {
        try {
            const { data: { updateOpcion: { opcion } } }  = await apolloClient
                .mutate({ mutation: UPDATE_OPCION, variables: { ...payload }  });
            commit('UPDATE_OPCION', opcion);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteOpcion({ commit }, payload ) {
        try {
            const { data: { deleteOpcion: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_OPCION, variables: { ...payload }  });
            if (found){
                commit('DELETE_OPCION', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
