import gql from 'graphql-tag';

export const ALL_CHOFERES = gql`
  query allChoferes($estado: Int) {
    allChoferes(estado:$estado)
    {
        edges{
        node{
            id
            nombre
            apellidoPaterno
            apellidoMaterno
            celular
            licencia
            caducidadLicencia
            estado
             taxi{
                id
                modelo
                marca
                matricula
                cantPasajeros
             }
    
        }
    }
    }
}
`;

export const CHOFER = gql`
  query chofer($id: ID!) {
    chofer(id:$id) {
      id
      nombre
      apellidoPaterno
      apellidoMaterno
      celular
      licencia
      caducidadLicencia
  }
}
`;


