import gql from 'graphql-tag';

export const CREATE_EMPLEADO = gql`
mutation CreateEmpleado($numero: String! $nombre: String! $apellidoPaterno: String!
 $apellidoMaterno: String! $celular: String! $planta: ID! $departamento: ID ){
 createEmpleado(input: { numero:$numero  nombre:$nombre apellidoPaterno:$apellidoPaterno 
 apellidoMaterno:$apellidoMaterno celular:$celular planta:$planta departamento:$departamento}) {
  empleado{ 
      id
      numero
      nombre
      apellidoPaterno
      apellidoMaterno
      celular
      planta{
        id
        nombre
      }
      departamento{
        id
        nombre
      }
  }
}
}
`;

export const UPDATE_EMPLEADO = gql`
mutation UpdateEmpleado($id: ID! $numero: String! $nombre: String! $apellidoPaterno: String! 
$apellidoMaterno: String! $celular: String! $planta: ID! $departamento: ID){
 updateEmpleado(id:$id input: { numero:$numero nombre:$nombre apellidoPaterno:$apellidoPaterno 
 apellidoMaterno:$apellidoMaterno celular:$celular planta:$planta departamento:$departamento}) {
  empleado{
      id
      numero
      nombre
      apellidoPaterno
      apellidoMaterno
      celular
      planta{
        id
        nombre
      }
      departamento{
        id
        nombre
      }
  }
}
}
`;

export const DELETE_EMPLEADO = gql`
mutation DeleteEmpleado($id: ID!){
deleteEmpleado(id:$id) {
  found
}
}
`;
