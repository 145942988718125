import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_DOMICILIOS } from '../graphql/query/domicilioQueries';
import { CREATE_DOMICILIO, UPDATE_DOMICILIO, DELETE_DOMICILIO } from '../graphql/mutation/domicilioMutation';


Vue.use(Vuex);

const state = () => ({
    domicilios: [],
    pagination: [],
});

const getters = {
    domicilios: state => state.domicilios,
};

const mutations = {
    SET_DOMICILIOS: (state, payload) => state.domicilios = payload,
    ADD_DOMICILIO: (state, payload) => state.domicilios.push(payload),
    DELETE_DOMICILIO: (state, payload) => state.domicilios = state.domicilios.filter(el => el.id !== payload.id),
    UPDATE_DOMICILIO: (state, payload) => state.domicilios = state.domicilios.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allDomicilios({ commit } ) {
        try {
            const { data: { allDomicilios: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_DOMICILIOS });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_DOMICILIOS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createDomicilio({ commit }, payload ) {
        try {
            const { data: { createDomicilio: { domicilio } } }  = await apolloClient
                .mutate({ mutation: CREATE_DOMICILIO, variables: { ...payload }  });
            commit('ADD_DOMICILIO', domicilio);
        } catch (e) {
            console.log(e);
        }
    },
    async updateDomicilio({ commit }, payload ) {
        try {
            const { data: { updateDomicilio: { domicilio } } }  = await apolloClient
                .mutate({ mutation: UPDATE_DOMICILIO, variables: { ...payload }  });
            commit('UPDATE_DOMICILIO', domicilio);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteDomicilio({ commit }, payload ) {
        try {
            const { data: { deleteDomicilio: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_DOMICILIO, variables: { ...payload }  });
            if (found){
                commit('DELETE_DOMICILIO', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
