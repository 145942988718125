import gql from 'graphql-tag';

export const CREATE_PEDIDO = gql`
mutation CreatePedido($fecha: Date! $hora: Time! $tipoPedido: ID! ){
 createPedido(input: { fecha:$fecha hora:$hora tipoPedido:$tipoPedido }) {
  pedido{ 
    id
    fecha
    hora 
    estado
    tipoPedido{
      id
      nombre
    }
   
  }
}
}
`;

export const CREATE_TRASLADO = gql`
mutation CreateTraslado($pedido: ID! $empleado: ID! $origen: ID!  $destino: ID!){
 createTraslado(input: { pedido:$pedido empleado:$empleado origen:$origen destino:$destino}) {
  traslado{ 
    pedido{
        id
        fecha
        hora
      }
      empleado{
        id
        numero
        nombre
      }
      origen{
        id
      }
      destino{
        id
      }
      chofer{
        nombre
      }
  }

}
}
`;

export const UPDATE_PEDIDO = gql`
mutation UpdatePedido($id: ID! $fecha: Date! $hora: Time! $tipoPedido: ID! $estado: Int){
 updatePedido(id:$id input: { fecha:$fecha hora:$hora tipoPedido:$tipoPedido estado:$estado}) {
  pedido{ 
    id
    fecha
    hora 
    estado
    tipoPedido{
      id
      nombre
    }
  }
}
}
`;

export const DELETE_PEDIDO = gql`
mutation DeletePedido($id: ID!){
deletePedido(id:$id) {
  found
}
}
`;
