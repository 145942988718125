import gql from 'graphql-tag';

export const ALL_TRASLADOS = gql`
  query allTraslados($pedido: ID) {
   allTraslados(pedido:$pedido){
  edges{
    node{
      id
      pedido{
        id
        fecha
        hora
      }
      empleado{
        id
        numero
        nombre
        apellidoPaterno
        apellidoMaterno
        dir
      }
      chofer{
        id
        nombre
      }
      origen{
        id
        direccion{
          id
          nombre
        }
      }
      destino{
        id
        direccion{
          nombre
          numero
        }
      }
    }
  }
}
}
`;

export const TRASLADO = gql`
  query traslado($id: ID!) {
    traslado(id:$id) {
      id
      pedido{
        id
        fecha
        hora
      }
      empleado{
        id
        numero
        nombre
        apellidoPaterno
        apellidoMaterno
        dir
        
      }
      origen{
        id
        direccion{
          id
          nombre
        }
      }
      destino{
        id
        direccion{
          nombre
          numero
        }
      }
  }
}
`;


