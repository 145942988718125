import gql from 'graphql-tag';

export const CREATE_CHOFER = gql`
mutation CreateChofer($nombre: String! $apellidoPaterno: String!
 $apellidoMaterno: String! $celular: String! $taxi: ID! $caducidadLicencia: Date $licencia: String){
 createChofer(input: { nombre:$nombre apellidoPaterno:$apellidoPaterno 
 apellidoMaterno:$apellidoMaterno celular:$celular taxi:$taxi 
 caducidadLicencia:$caducidadLicencia licencia: $licencia}) {
  chofer{ 
      id
      nombre
      apellidoPaterno
      apellidoMaterno
      celular
      licencia
      caducidadLicencia
      estado
      taxi{
        id
        modelo
        marca
        matricula
        cantPasajeros
      }
  }
}
}
`;

export const UPDATE_CHOFER = gql`
mutation UpdateChofer($id: ID! $nombre: String! $apellidoPaterno: String!
 $apellidoMaterno: String! $celular: String! $taxi: ID! $caducidadLicencia: Date $licencia: String){
 updateChofer(id:$id input: { nombre:$nombre apellidoPaterno:$apellidoPaterno 
 apellidoMaterno:$apellidoMaterno celular:$celular taxi:$taxi 
 caducidadLicencia:$caducidadLicencia licencia: $licencia}) {
  chofer{
      id
      nombre
      apellidoPaterno
      apellidoMaterno
      celular
      licencia
      caducidadLicencia
      estado
      taxi{
        id
        modelo
        marca
        matricula
        cantPasajeros
      }
  }
}
}
`;

export const DELETE_CHOFER = gql`
mutation DeleteChofer($id: ID!){
deleteChofer(id:$id) {
  found
}
}
`;
