import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_EMPRESAS } from '../graphql/query/empresaQueries';
import { CREATE_EMPRESA, UPDATE_EMPRESA, DELETE_EMPRESA } from '../graphql/mutation/empresaMutation';


Vue.use(Vuex);

const state = () => ({
    empresas: [],
    pagination: [],
});

const getters = {
    empresas: state => state.empresas,
};

const mutations = {
    SET_EMPRESAS: (state, payload) => state.empresas = payload,
    ADD_EMPRESA: (state, payload) => state.empresas.push(payload),
    DELETE_EMPRESA: (state, payload) => state.empresas = state.empresas.filter(el => el.id !== payload.id),
    UPDATE_EMPRESA: (state, payload) => state.empresas = state.empresas.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allEmpresas({ commit } ) {
        try {
            const { data: { allEmpresas: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_EMPRESAS });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_EMPRESAS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createEmpresa({ commit }, payload ) {
        try {
            const { data: { createEmpresa: { empresa } } }  = await apolloClient
                .mutate({ mutation: CREATE_EMPRESA, variables: { ...payload }  });
            commit('ADD_EMPRESA', empresa);
        } catch (e) {
            console.log(e);
        }
    },
    async updateEmpresa({ commit }, payload ) {
        try {
            const { data: { updateEmpresa: { empresa } } }  = await apolloClient
                .mutate({ mutation: UPDATE_EMPRESA, variables: { ...payload }  });
            commit('UPDATE_EMPRESA', empresa);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteEmpresa({ commit }, payload ) {
        try {
            const { data: { deleteEmpresa: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_EMPRESA, variables: { ...payload }  });
            if (found){
                commit('DELETE_EMPRESA', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
