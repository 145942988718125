import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_PLANTAS } from '../graphql/query/plantaQueries';
import { CREATE_PLANTA, UPDATE_PLANTA, DELETE_PLANTA } from '../graphql/mutation/plantaMutation';


Vue.use(Vuex);

const state = () => ({
    plantas: [],
    pagination: [],
});

const getters = {
    plantas: state => state.plantas,
};

const mutations = {
    SET_PLANTAS: (state, payload) => state.plantas = payload,
    ADD_PLANTA: (state, payload) => state.plantas.push(payload),
    DELETE_PLANTA: (state, payload) => state.plantas = state.plantas.filter(el => el.id !== payload.id),
    UPDATE_PLANTA: (state, payload) => state.plantas = state.plantas.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allPlantas({ commit } ) {
        try {
            const { data: { allPlantas: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_PLANTAS });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_PLANTAS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createPlanta({ commit }, payload ) {
        try {
            const { data: { createPlanta: { planta } } }  = await apolloClient
                .mutate({ mutation: CREATE_PLANTA, variables: { ...payload }  });
            commit('ADD_PLANTA', planta);
        } catch (e) {
            console.log(e);
        }
    },
    async updatePlanta({ commit }, payload ) {
        try {
            const { data: { updatePlanta: { planta } } }  = await apolloClient
                .mutate({ mutation: UPDATE_PLANTA, variables: { ...payload }  });
            commit('UPDATE_PLANTA', planta);
        } catch (e) {
            console.log(e);
        }
    },
    async deletePlanta({ commit }, payload ) {
        try {
            const { data: { deletePlanta: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_PLANTA, variables: { ...payload }  });
            if (found){
                commit('DELETE_PLANTA', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
