<template>
  <div>
    <v-card class="mr-13">
      <v-toolbar color="primary" dark shaped>
        <v-toolbar-title>Listado de Departamentos</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="mr-16">
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              shaped
              single-line
              hide-details
          ></v-text-field>
        </div>
        <v-btn
            color="secondary"
            absolute
            top
            right
            fab
            @click="toogleForm"
            :disabled="showForm"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
          :headers="header"
          :items="departamentos"
          :search="search"
          :loading="loading"
          loading-text="Cargando... Espere porfavor"
          @change="loadData"
          no-data-text="No existen Departamentos registrados en el sistema."
          :item-key="departamentos.id"

      >
        <template v-slot:body="props" >
          <tbody name="slide" is="transition-group" mode="out-in" appear>
          <template >
            <tr v-for="(item, index) in props.items" :key="item.id">
              <td>{{index+1}}</td>
              <td>{{item.nombre}}</td>
              <td>{{item.domicilio.nombre + ' número: ' + item.domicilio.numero +
              ' Col: ' + item.domicilio.colonia.nombre + ' CP: ' + item.domicilio.colonia.cp
              +' '+ item.domicilio.colonia.municipio.code +', '+ item.domicilio.colonia.municipio.estado.code
              +', '+ item.domicilio.colonia.municipio.estado.pais.code}}</td>
              <td>{{item.empresa.nombre}}</td>
              <td>
                <TableAction :item="item" @update="toogleForm" @delaction="deleteItem"/>
              </td>
            </tr>
          </template>
          </tbody>
        </template>

      </v-data-table>
    </v-card>
    <template>
      <departamento-form v-if="showForm" :title="title" :show-form="showForm"
                 :departamento="editItem" @close="toogleForm" />
    </template>
    <template>
      <v-dialog persistent max-width="200" transition="dialog-top-transition" v-if="menuLocked" v-model="menuLocked">
        <v-card>
          <v-card-title>Eliminar</v-card-title>
          <v-card-text>Seguro que desea eliminar {{itemToDelete.nombre}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="terciary"
                text
                @click="deleteItemCancel"
                icon
            >
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
                color="secondary"
                text
                @click="deleteItemConfirm"
                icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DepartamentoForm from "../../components/Departamento/DepartamentoForm";
import TableAction from "../../components/TableAction";

export default {
  name: "DepartamentoList",
  components: {TableAction, DepartamentoForm},
  data:()=>({
    loading: false,
    showForm: false,
    search: '',
    title:'',
    defaultEditItem: null,
    editItem: null,
    itemToDelete: null,
    menuLocked: false,
    deleteConfirmId: '',
    header: [
      { text: 'No', value: 'num', sortable: false },
      { text: 'Nombre', value: 'nombre' },
      { text: 'Dirección', value: 'domicilio' },
      { text: 'Empresa', value: 'empresa.nombre' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
  }),
  computed: {
    ...mapGetters(['departamentos']),
  },
  mounted() {
    if(this.departamentos.length<=0){
      this.loadData()
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("allDepartamentos");
      await this.$store.dispatch("allEmpresas");
      await this.$store.dispatch("allDomicilios");
      this.loading = false;
    },
    toogleForm(item){
      this.departamentos.indexOf(item)=== -1 ? (this.editItem= {}, this.title='Nuevo Departamento') :
          (this.editItem = {...item}, this.title='Actualizar Departamento');
      this.showForm = ! this.showForm;
    },

    deleteItem(item) {
      this.itemToDelete = item
      this.menuLocked = true

    },

    async deleteItemConfirm() {
      if (this.itemToDelete !== null) {
        await this.$store.dispatch("deleteDepartamento", this.itemToDelete);
        this.menuLocked = false;
        this.itemToDelete = null;

      }
    },

    deleteItemCancel() {
      this.menuLocked = false
      this.itemToDelete = null
    },
  },
}
</script>

<style scoped>
.slide-enter-active {
  animation: slide-in 1s ease-out forwards;
}
.slide-live-active {
  animation: slide-out 1s ease-out forwards;
}
@keyframes slide-in {
  from {
    transform: translateY(-30px);
  } to {
      transform: translateY(0);
    }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  } to {
      transform: translateY(-30px);
    }
}
</style>
