import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_ESTADOS } from '../graphql/query/estadoQueries';
import { CREATE_ESTADO, UPDATE_ESTADO, DELETE_ESTADO } from '../graphql/mutation/estadoMutation';


Vue.use(Vuex);

const state = () => ({
    estados: [],
    pagination: [],
});

const getters = {
    estados: state => state.estados,
};

const mutations = {
    SET_ESTADOS: (state, payload) => state.estados = payload,
    ADD_ESTADO: (state, payload) => state.estados.push(payload),
    DELETE_ESTADO: (state, payload) => state.estados = state.estados.filter(el => el.id !== payload.id),
    UPDATE_ESTADO: (state, payload) => state.estados = state.estados.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allEstados({ commit } ) {
        try {
            const { data: { allEstados: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_ESTADOS });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_ESTADOS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createEstado({ commit }, payload ) {
        try {
            const { data: { createEstado: { estado } } }  = await apolloClient
                .mutate({ mutation: CREATE_ESTADO, variables: { ...payload }  });
            commit('ADD_ESTADO', estado);
        } catch (e) {
            console.log(e);
        }
    },
    async updateEstado({ commit }, payload ) {
        try {
            const { data: { updateEstado: { estado } } }  = await apolloClient
                .mutate({ mutation: UPDATE_ESTADO, variables: { ...payload }  });
            commit('UPDATE_ESTADO', estado);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteEstado({ commit }, payload ) {
        try {
            const { data: { deleteEstado: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_ESTADO, variables: { ...payload }  });
            if (found){
                commit('DELETE_ESTADO', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
