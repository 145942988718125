import gql from 'graphql-tag';

export const CREATE_MUNICIPIO = gql`
mutation CreateMunicipio($estado: ID! $nombre: String! $code: String!){
 createMunicipio(input: { estado:$estado nombre:$nombre code:$code}) {
  municipio{ 
   id
   nombre
   code
   estado{
    id
    nombre
   }
  }
}
}
`;

export const UPDATE_MUNICIPIO = gql`
mutation UpdateMunicipio($id: ID! $estado: ID! $nombre: String! $code:String!){
 updateMunicipio(id:$id input: { estado:$estado, nombre:$nombre, code:$code }) {
  municipio{
    id
    nombre
    code
    estado{
     id
     nombre
    }
  }
}
}
`;

export const DELETE_MUNICIPIO = gql`
mutation DeleteMunicipio($id: ID!){
deleteMunicipio(id:$id) {
  found
}
}
`;
