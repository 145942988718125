import gql from 'graphql-tag';

export const ALL_LOCACIONES =gql`
  query allLocaciones{
   allLocaciones
      {
       edges{
        node{
          id
          nombre
          codigo
          empresa{
            id
            nombre
          }
        }
      }
      }
  }
`;
