import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_TAXIS } from '../graphql/query/taxiQueries';
import { CREATE_TAXI, UPDATE_TAXI, DELETE_TAXI } from '../graphql/mutation/taxiMutation';


Vue.use(Vuex);

const state = () => ({
    taxis: [],
    pagination: [],
});

const getters = {
    taxis: state => state.taxis,
};

const mutations = {
    SET_TAXIS: (state, payload) => state.taxis = payload,
    ADD_TAXI: (state, payload) => state.taxis.push(payload),
    DELETE_TAXI: (state, payload) => state.taxis = state.taxis.filter(el => el.id !== payload.id),
    UPDATE_TAXI: (state, payload) => state.taxis = state.taxis.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allTaxis({ commit } ) {
        try {
            const { data: { allTaxis: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_TAXIS });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_TAXIS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createTaxi({ commit }, payload ) {
        try {
            const { data: { createTaxi: { taxi } } }  = await apolloClient
                .mutate({ mutation: CREATE_TAXI, variables: { ...payload }  });
            commit('ADD_TAXI', taxi);
        } catch (e) {
            console.log(e);
        }
    },
    async updateTaxi({ commit }, payload ) {
        try {
            const { data: { updateTaxi: { taxi } } }  = await apolloClient
                .mutate({ mutation: UPDATE_TAXI, variables: { ...payload }  });
            commit('UPDATE_TAXI', taxi);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteTaxi({ commit }, payload ) {
        try {
            const { data: { deleteTaxi: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_TAXI, variables: { ...payload }  });
            if (found){
                commit('DELETE_TAXI', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
