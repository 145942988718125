<template>
  <v-dialog
      v-model="showForm"
      max-width="600px"
      transition="dialog-top-transition"
      persistent
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          <h4 class="title">{{title}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <div >
              <v-text-field
                  v-model="domicilio.nombre"
                  label="Nombre"
              />
            </div>
            <div >
              <v-text-field
                  v-model="domicilio.numero"
                  label="Número"
              />
            </div>
            <div >
<!--              <GoogleMap />-->

            </div>
            <div>

              <v-autocomplete
                  v-model="colonia"
                  :items="colonias"
                  item-text="nombre"
                  item-value="id"
                  label="Seleccionar Colonia"
                  chips
                  clearable
                  deletable-chips
                  small-chips
                  hint="Colonia"
                  persistent-hint
              ></v-autocomplete>

            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="terciary" dark @click="closed">
            Cancelar
          </v-btn>
          <v-btn v-if="title==='Actualizar Domicilio'" color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
          <v-btn v-else color="secondary" @click="create" :loading="loading">
            Crear
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import GoogleMap from '../GoogleMaps/GoogleMap'

export default {
  name: "DomicilioForm",
  // eslint-disable-next-line vue/no-unused-components
  components: {GoogleMap},
  props: {
    title: String,
    domicilio: {},
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  data: ()=>({
    loading: false,
    marker1: true,
    marker2: true,
    colonia: {},
  }),
  computed: {
    ...mapGetters(['colonias']),
  },
  mounted() {
    this.colonia = this.domicilio.colonia ? this.domicilio.colonia.id : null ;
  },
  methods: {
    async create() {
      this.loading= true;
      this.domicilio.colonia = this.colonia;
      await this.$store.dispatch('createDomicilio', this.domicilio);
      this.loading= false;
      this.closed();
    },
    toggleMarkerOne() {
      this.marker1 = !this.marker1
    },
    toggleMarkerTow() {
      this.marker2 = !this.marker2
    },
    async update() {
      this.loading= true;
      this.domicilio.colonia = this.colonia;
      await this.$store.dispatch('updateDomicilio', this.domicilio);
      this.loading= false;
      this.closed();
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>
