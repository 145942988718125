import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_HORARIOS } from '../graphql/query/horarioQueries';
import { CREATE_HORARIO, UPDATE_HORARIO, DELETE_HORARIO } from '../graphql/mutation/horarioMutation';


Vue.use(Vuex);

const state = () => ({
    horarios: [],
    pagination: [],
});

const getters = {
    horarios: state => state.horarios,
};

const mutations = {
    SET_HORARIOS: (state, payload) => state.horarios = payload,
    ADD_HORARIO: (state, payload) => state.horarios.push(payload),
    DELETE_HORARIO: (state, payload) => state.horarios = state.horarios.filter(el => el.id !== payload.id),
    UPDATE_HORARIO: (state, payload) => state.horarios = state.horarios.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allHorarios({ commit } ) {
        try {
            const { data: { allHorarios: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_HORARIOS });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_HORARIOS', nodes);
        } catch (e) {
            console.log(e);
        }
    },


    async createHorario({ commit }, payload ) {
        try {
            const { data: { createHorario: { horario } } }  = await apolloClient
                .mutate({ mutation: CREATE_HORARIO, variables: { ...payload }  });
            commit('ADD_HORARIO', horario);
        } catch (e) {
            console.log(e);
        }
    },
    async updateHorario({ commit }, payload ) {
        try {
            const { data: { updateHorario: { horario } } }  = await apolloClient
                .mutate({ mutation: UPDATE_HORARIO, variables: { ...payload }  });
            commit('UPDATE_HORARIO', horario);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteHorario({ commit }, payload ) {
        try {
            const { data: { deleteHorario: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_HORARIO, variables: { ...payload }  });
            if (found){
                commit('DELETE_HORARIO', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
