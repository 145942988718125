<template>
  <v-dialog
      v-model="showForm"
      max-width="400px"
      transition="dialog-top-transition"
      persistent
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          <h4 class="title">{{title}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <div >
              <v-text-field
                  v-model="empleado.numero"
                  label="Número"
                  type="Number"
              />
              <v-text-field
                  v-model="empleado.nombre"
                  label="Nombre"
              />
              <v-text-field
                  v-model="empleado.apellidoPaterno"
                  label="Primer Apellido"
              />
              <v-text-field
                  v-model="empleado.apellidoMaterno"
                  label="Segundo Apellido"
              />
              <v-text-field
                  v-model="empleado.celular"
                  label="Número Celular"
              />
              <v-autocomplete
                  v-model="planta"
                  :items="plantas"
                  item-text="nombre"
                  item-value="id"
                  label="Selecciona la Planta"
                  chips
                  clearable
                  small-chips
                  hint="Planta"
                  persistent-hint
              />
              <v-autocomplete
                  v-model="departamento"
                  :items="departamentos"
                  item-text="nombre"
                  item-value="id"
                  label="Selecciona el Departamento"
                  chips
                  clearable
                  small-chips
                  hint="Departamento"
                  persistent-hint
              />
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="terciary" @click="closed">
            Cancelar
          </v-btn>
          <v-btn v-if="title==='Actualizar Colaborador'" color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
          <v-btn v-else color="secondary" @click="create" :loading="loading">
            Crear
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "EmpleadoForm",
  props: {
    title: String,
    empleado: {},
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['departamentos','plantas']),
  },
  data: ()=>({
    loading: false,
    planta:{},
    departamento: {},
  }),
  mounted() {
    this.planta = this.empleado.planta ? this.empleado.planta.id : null;
    this.departamento = this.empleado.departamento ? this.empleado.departamento.id : null;
    if (this.departamentos.length<=0 || this.plantas.length<=0){
      this.loadData();
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch('allDepartamentos');
      await this.$store.dispatch('allPlantas');
      this.loading= false;
    },
    async create() {
      this.loading= true;
      this.empleado.planta = this.planta;
      this.empleado.departamento = this.departamento;
      await this.$store.dispatch('createEmpleado', this.empleado);
      this.loading= false;
      this.closed();
    },
    async update() {
      this.loading= true;
      this.empleado.planta = this.planta;
      this.empleado.departamento = this.departamento;
      await this.$store.dispatch('updateEmpleado', this.empleado);
      this.loading= false;
      this.closed();
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>
