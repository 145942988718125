import gql from 'graphql-tag';

export const ALL_ESTADOS = gql`
  query allEstados {
    allEstados {
    edges{
      node{
        id
        nombre
        code
        pais{
          id
          nombre
          code
        }
      }
    }
  }
}
`;
