<template>
  <v-row class="fill-height">
    <v-col>
      <v-progress-linear v-show="loading" color="primary"/>
      <v-sheet v-show="!loading" height="64">
        <v-toolbar
            shaped
            color="primary"
            dark
        >
          <v-btn
              outlined
              class="mr-4"
              color="white"
              @click="setToday"
          >
            Hoy
          </v-btn>
          <v-btn
              fab
              text
              small
              color="white"
              @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
              fab
              text
              small
              color="white"
              @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
              bottom
              right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  outlined
                  color="white"
                  v-bind="attrs"
                  v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Día</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mes</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 días</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet v-show="!loading" height="600">
        <v-sheet height="600">
          <v-calendar
              :key="calendarKey"
              ref="calendar"
              v-model="value"
              color="primary"
              :type="type"
              locale="ES"
              :events="events"
              :event-color="getEventColor"
              :event-ripple="false"
              @change="getEvents"
              @click:more="viewDay"
              @mousemove:event="showEvent"
              @mousedown:event="startDrag"
              @mousedown:time="startTime"
              @mousemove:time="mouseMove"
              @mouseup:event="endDrag"
              @mouseleave.native="cancelDrag"
          >
            <template v-slot:event="{ event, timed, eventSummary }" >
              <div
                  class="v-event-draggable"
                  v-html="eventSummary()"
              ></div>
              <div
                  v-if="timed"
                  class="v-event-drag-bottom"
                  @mousedown.stop="extendBottom(event)"
              ></div>
            </template>
          </v-calendar>
        </v-sheet>
        <pedido-form v-if="selectedOpen" :title="title" :show-form="selectedOpen"
                     :pedido="editItem" :start="start" @close="()=>{ selectedOpen=false}" @updated="getEvents" />
        <traslado-form v-if="editTraslados" :show-form="editTraslados" :pedido-id="editItem"
                       @close="editTraslados=false" @updated="renderCalendar"/>
        <v-menu
            v-model="menuOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
        >
          <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
          >
            <v-toolbar
                :color="selectedEvent.color"
                dark
            >
              <v-btn icon @click="()=>{editTraslados=true; editItem= selectedEvent.id}"
                     v-show="user.isSuperuser || user.isStaff">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="()=>{deleteItem(selectedEvent)}">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="`Solicitud emitida por: ${this.owner.nombre ? this.owner.nombre : '-'}`"></span><br/>
              <span v-html="`Planta: ${this.owner.planta ? this.owner.planta : '-'}`"></span><br/>
              <span v-html="`Departamento: ${this.owner.departamento ? this.owner.departamento : '-'}`"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  text
                  color="secondary"
                  @click="menuOpen = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
      <div>
        <template>
          <v-dialog persistent max-width="200" transition="dialog-top-transition" v-if="menuLocked" v-model="menuLocked">
            <v-card>
              <v-card-title>Eliminar</v-card-title>
              <v-card-text>Seguro que desea eliminar esta solicitud de {{itemToDelete.name}} </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="terciary"
                    text
                    @click="deleteItemCancel"
                    icon
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn
                    color="secondary"
                    text
                    @click="deleteItemConfirm"
                    icon
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </div>
    </v-col>
  </v-row>

</template>

<script>
import PedidoForm from "../Pedido/PedidoForm";
import TrasladoForm from "../Traslado/TrasladoForm";
import {mapGetters} from "vuex";
export default {
  name: 'Calendario2',
  components:{TrasladoForm, PedidoForm},
  data: () => ({
    loading: false,
    value: '',
    title: '',
    calendarKey : 0,
    start: Number,
    editItem: null,
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    type: 'week',
    typeToLabel: {
      month: 'Mes',
      week: 'Semana',
      day: 'Día',
      '4day': '4 Días',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    owner: {},
    menuOpen: false,
    editTraslados: false,
    itemToDelete: null,
    menuLocked: false,
    deleteConfirmId: '',
    events: [],
    colors: ['red', 'orange', 'green', 'blue'],
    names: ['Eliminada', 'Pendiente', 'Aceptada', 'Procesada'],
  }),
  computed: {
    ...mapGetters(['pedidos', 'user']),
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("allPedidos");
    await this.getEvents();
    this.loading = false;
  },
  methods: {
    async renderCalendar(){
      this.getEvents();
    },
    startDrag ({event, timed}) {

      if (event && timed ) {

          this.dragEvent = event
          this.dragTime = null
          this.extendOriginal = null


      }
    },
    startTime (tms) {
      const date = new Date(`${tms.date}T${tms.time}`);
      const now = Date.now()
      const mouse = this.toTime(tms)
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start

        this.dragTime = mouse - start
      } else if(tms.future && date > now) {
        this.createStart = this.roundTime(mouse)
        const fecha = tms.date;
        let hora = tms.time;
        let { weekday } = tms;
        this.start = weekday;
        this.editItem= {
          fecha,
          hora
        };
        this.title='Nueva Solicitud';
        this.selectedOpen = true;
      }else{
        this.$notify({
          message:
              'La fecha y la hora de la solicitud deben ser mayor a la actual',
          icon: 'add_alert',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'warning',
        });
      }
    },
    extendBottom (event) {
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    mouseMove (tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
    },
    endDrag ({event, eventParsed, day}) {

      if (day.future){
        const item = this.pedidos.filter(el => el.id === event.id)[0];
        this.editItem= {
          ...item,
          fecha: eventParsed.start.date,
          hora: eventParsed.start.time
        };
        this.title='Actualizar Solicitud';
        this.start = day.weekday;
        this.selectedOpen = true;
      }else {
        this.$notify({
          message:
              'Las Solicitudes pasadas no se pueden modificar',
          icon: 'add_alert',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'warning',
        });
      }
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null

    },
    cancelDrag () {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }



      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    getOwner (eventid) {
      this.owner = {}
      let own = this.pedidos.filter(e=> e.id === eventid)[0].owner
      let otro = own.userUsuarioempresa.edges.map(e=> e.node.empleado)[0]
      console.log(otro)
      if(otro !== undefined){
        this.owner = {"nombre": `${otro.nombre} ${otro.apellidoPaterno} ${otro.apellidoMaterno}`,
          "planta": otro.planta.nombre, "departamento": otro.departamento.nombre}
      }
    },
    roundTime (time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
    },
    toTime (tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    getEvents () {
     let events = [];
      this.pedidos.forEach(e =>(
          events.push(
              {
                id: e.id,
                name: e.tipoPedido.nombre,
                color: this.colors[e.estado],
                start: new Date(`${e.fecha}T${e.hora}`),
                timed: true
              }
          )
          ));
      this.events = events;
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    rndElement (arr) {
      return arr[this.rnd(0, arr.length - 1)]
    },

    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.getOwner(event.id)
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.menuOpen = true))
      }

      if (this.menuOpen) {
        this.menuOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange () {
      this.events = this.pedidos.map(e=>{
        return {
          name: e.tipoPedido.nombre,
          start: e.fecha,
          end: e.fecha,
          timed: e.hora,
        };
      });

    },

    deleteItem(item) {
      this.itemToDelete = item
      this.menuLocked = true

    },

    async deleteItemConfirm() {
      if (this.itemToDelete !== null) {
        let p = this.pedidos.filter(el => el===this.itemToDelete.id ? el: null)
        let payload = {...p, estado: 0}
        await this.$store.dispatch("updatePedido", payload);
        this.menuLocked = false;
        this.itemToDelete = null;
        this.getEvents();

      }
    },

    deleteItemCancel() {
      this.menuLocked = false
      this.itemToDelete = null
    },
  },
}
</script>
