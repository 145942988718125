import gql from 'graphql-tag';

export const CREATE_OPCION = gql`
mutation CreateOpcion($nombre: String!){
 createOpcion(input: { nombre:$nombre}) {
  opcion{ 
   id
   nombre
  }
}
}
`;

export const UPDATE_OPCION = gql`
mutation UpdateOpcion($id: ID! $nombre: String!){
 updateOpcion(id:$id input: { nombre:$nombre}) {
  opcion{
    id
    nombre
  }
}
}
`;

export const DELETE_OPCION = gql`
mutation DeleteOpcion($id: ID!){
deleteOpcion(id:$id) {
  found
}
}
`;
