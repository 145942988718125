import gql from 'graphql-tag';

export const LOGIN_USER = gql`
mutation TokenAuth($username: String!, $password: String!){
  tokenAuth(input:{username:$username, password:$password})
  {
    token
    success
    errors
  }
}
`;

export const CREATE_USER_BY_ADMIN = gql`
mutation createUserByAdmin($username: String! $firstName: String! $lastName: String!
 $email: String! $password: String! $image: Upload $isSuperuser: Boolean $isStaff: Boolean $groups:[ID] ) {
    createUserByAdmin( input: { username: $username firstName: $firstName lastName: $lastName
     email: $email password: $password image: $image isSuperuser: $isSuperuser isStaff: $isStaff groups: $groups
    }) {
      user{
       id
        username
        email
        firstName
        lastName
        isSuperuser
        isStaff
        groups{
          edges{
            node{
              id
              name
            }
          }
        }
    }
    }
}
`;

export const UPDATE_USER_BY_ADMIN = gql`
mutation updateUserByAdmin($id: ID! $username: String! $firstName: String! $lastName: String!
 $email: String! $image: Upload $isSuperuser: Boolean $isStaff: Boolean $groups:[ID] ) {
    updateUserByAdmin(id: $id input: { username: $username firstName: $firstName lastName: $lastName
     email: $email image: $image isSuperuser: $isSuperuser isStaff: $isStaff groups: $groups
    }) {
      user{
       id
        username
        email
        firstName
        lastName
        isSuperuser
        isStaff
        groups{
          edges{
            node{
              id
              name
            }
          }
        }
    }
    }
}
`;

export const VERIFY_ACCOUNT_BY_ADMIN = gql`
mutation verifyAccountByAdmin($id: ID! ) {
    verifyAccountByAdmin( id: $id ) {
      user{
       id
    }
    }
}
`;

export const UPDATE_ACCOUNT = gql`
mutation updateAccount($firstName: String $lastName: String ) {
    updateAccount( input: { firstName: $firstName, lastName: $lastName }) {
    success
    errors
    }
}
`;

export const UPDATE_USER = gql`
mutation updateProfile($id: ID! $firstName: String $lastName: String ) {
    updateAccount( input: { idfirstName: $firstName, lastName: $lastName }) {
    success
    errors
    }
}
`;

export const UPDATE_PROFILE = gql`
mutation UpdateProfile($id: ID!, $username: String $firstName: String $lastName:String $file: Upload ){
  updateProfile(userId: $id username: $username, firstName: $firstName lastName: $lastName fileIn: $file){
    profile{
      token
    }
  }
}
`;

export const DELETE_ACCOUNT_BY_ADMIN = gql`
mutation deleteAccountByAdmin($id: ID!){
  deleteAccountByAdmin(id: $id){
    found
  }
}
`;
