import gql from 'graphql-tag';

export const CREATE_LOCACION=gql`
mutation CreateLocacion($nombre: String! $codigo: String! $empresa: ID!){
createLocacion(input: { nombre:$nombre codigo:$codigo empresa:$empresa}){
  locacion{
      id
      nombre
      codigo
      empresa{
        id
        nombre
      }
    }
}

}
`;

export const UPDATE_LOCACION=gql`
mutation UpdateLocacion($id:ID! $nombre: String! $codigo: String! $empresa: ID!){
updateLocacion(id: $id input: { nombre:$nombre codigo:$codigo empresa:$empresa}){
  locacion{
      id
      nombre
      codigo
      empresa{
        id
        nombre
      }
    }
}

}
`;


export const DELETE_LOCACION = gql`
mutation DeleteLocacion($id: ID!){
deleteLocacion(id:$id) {
  found
}
}
`;

