import gql from 'graphql-tag';

export const CREATE_ESTADO = gql`
mutation CreateEstado($pais: ID! $nombre: String! $code: String!){
 createEstado(input: { pais:$pais nombre:$nombre code:$code}) {
  estado{ 
   id
   nombre
   code
   pais{
    id
    nombre
    code
   }
  }
}
}
`;

export const UPDATE_ESTADO = gql`
mutation UpdateEstado($id: ID! $pais: ID! $nombre: String! $code:String!){
 updateEstado(id:$id input: { pais:$pais, nombre:$nombre, code:$code }) {
  estado{
    id
    nombre
    code
    pais{
    id
    nombre
    code
   }
  }
}
}
`;

export const DELETE_ESTADO = gql`
mutation DeleteEstado($id: ID!){
deleteEstado(id:$id) {
  found
}
}
`;
