import gql from 'graphql-tag';

export const CREATE_TAXI = gql`
mutation CreateTaxi($modelo: String! $marca: String! $matricula: String! $cantPasajeros: Int!
 $color: String $agenciaSeguro: String $caducidadSeguro: Date){
 createTaxi(input: { modelo:$modelo marca:$marca matricula:$matricula cantPasajeros:$cantPasajeros 
 color:$color agenciaSeguro:$agenciaSeguro caducidadSeguro:$caducidadSeguro}) {
  taxi{ 
   id
   modelo
   marca
   matricula
   cantPasajeros
   color
   agenciaSeguro
   caducidadSeguro
  }
}
}
`;

export const UPDATE_TAXI = gql`
mutation UpdateTaxi($id: ID! $modelo: String! $marca: String! $matricula: String! $cantPasajeros: Int!
 $color: String $agenciaSeguro: String $caducidadSeguro: Date){
 updateTaxi(id:$id input: { modelo:$modelo marca:$marca matricula:$matricula cantPasajeros:$cantPasajeros 
 color:$color agenciaSeguro:$agenciaSeguro caducidadSeguro:$caducidadSeguro}) {
  taxi{
    id
    modelo
    marca
    matricula
    cantPasajeros
    color
    agenciaSeguro
    caducidadSeguro
  }
}
}
`;

export const DELETE_TAXI = gql`
mutation DeleteTaxi($id: ID!){
deleteTaxi(id:$id) {
  found
}
}
`;
