<template>
  <v-dialog
      v-model="showForm"
      max-width="400px"
      transition="dialog-top-transition"
      persistent
  >
    <v-card>
      <v-toolbar fixed dark color="primary">
        <v-toolbar-title>
          <h4 class="title">{{title}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <div >
              <v-text-field
                  v-model="horario.nombre"
                  label="Nombre"
              />
              <v-menu
                  ref="menu"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="horario.hora"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="horario.hora"
                      label="Hora"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="horaFieldRules"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu1"
                    v-model="horario.hora"
                    full-width
                    @click:minute="$refs.menu.save(horario.hora)"
                ></v-time-picker>
              </v-menu>

              <v-select
                  v-model="empresa"
                  :items="empresas"
                  item-text="nombre"
                  item-value="id"
                  label="Selecciona la Empresa"
                  chips
                  hint="Empresa"
                  persistent-hint
              ></v-select>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="terciary" dark @click="closed">
            Cancelar
          </v-btn>
          <v-btn v-if="title==='Actualizar Horario'" color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
          <v-btn v-else color="secondary" @click="create" :loading="loading">
            Crear
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "HorarioForm",
  props: {
    title: String,
    horario: {},
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  data: ()=>({
    loading: false,
    empresa: {},
    menu1: false,
  }),
  computed: {
    ...mapGetters(['empresas']),
  },
  mounted() {
    this.empresa = this.horario.empresa ? this.horario.empresa.id : null ;
  },
  methods: {
    async create() {
      this.loading= true;
      this.horario.empresa = this.empresa;
      await this.$store.dispatch('createHorario', this.horario);
      this.loading= false;
      this.closed();
    },
    async update() {
      this.loading= true;
      this.horario.empresa = this.empresa;
      await this.$store.dispatch('updateHorario', this.horario);
      this.loading= false;
      this.closed();
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>
