<template>
  <v-dialog
      v-model="showForm"
      max-width="600px"
      transition="dialog-top-transition"
      persistent
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          <h4 class="title">{{title}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <div >
              <v-text-field
                  v-model="user.username"
                  label="Usuario"
                  :disabled="title==='Actualizar Usuario'"
              />
            </div>
            <div>
              <v-row>
                <v-checkbox v-model="user.isSuperuser" label="Administrador"/>
                <v-checkbox  class="ml-12" v-model="user.isStaff" label="Del Equipo"/>
              </v-row>
            </div>
            <div>

               <v-autocomplete
                   v-show="!user.isSuperuser"
                   v-if="!user.isStaff"
                   v-model="grupos"
                   :items="groups"
                   item-text="name"
                   item-value="id"
                   label="Seleccionar Roles"
                   multiple
                   chips
                   clearable
                   deletable-chips
                   small-chips
                   hint="Roles del Usuario"
                   persistent-hint
               ></v-autocomplete>

            </div>
            <div >
              <v-text-field
                  v-model="user.email"
                  label="Email"
                  :disabled="title==='Actualizar Usuario'"
              />
            </div>
            <div >
              <v-text-field
                  v-model="user.password"
                  label="Contraseña"
                  v-show="title!=='Actualizar Usuario'"
                  :type="marker1 ? 'password' : 'text'"
                  :append-outer-icon="marker1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-outer="toggleMarkerOne"
              />
            </div>
            <div >
              <v-text-field
                  v-model="user.firstName"
                  label="Nombre"
              />
            </div>
            <div >
              <v-text-field
                  v-model="user.lastName"
                  label="Apellidos"
              />
            </div>
            <div>
              <v-file-input clearable shaped label="Avatar" v-model="user.image"/>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="terciary" @click="closed">
                    Cancelar
                  </v-btn>
          <v-btn v-if="title==='Actualizar Usuario'" color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
          <v-btn v-else color="secondary" @click="create" :loading="loading">
          Crear
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "UserForm",
  props: {
    title: String,
    user: {},
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  data: ()=>({
    loading: false,
    marker1: true,
    marker2: true,
    grupos: [],
  }),
  computed: {
    ...mapGetters(['groups']),
  },
  mounted() {
    this.grupos = this.user.groups ? this.user.groups.edges.map(d=> d.node) : [] ;
  },
  methods: {
    async create() {
      this.loading= true;
      this.user.isSuperuser = this.user.isSuperuser ? this.user.isSuperuser : false;
      this.user.isStaff = this.user.isStaff ? this.user.isStaff : false;
      this.user.groups = this.grupos;
      await this.$store.dispatch('createUser', this.user);
      this.loading= false;
      this.closed();
    },
    toggleMarkerOne() {
      this.marker1 = !this.marker1
    },
    toggleMarkerTow() {
      this.marker2 = !this.marker2
    },
    async update() {
      this.loading= true;
      this.user.isSuperuser = this.user.isSuperuser ? this.user.isSuperuser : false;
      this.user.isStaff = this.user.isStaff ? this.user.isStaff : false;
      this.user.groups = this.grupos;
      await this.$store.dispatch('updateUser', this.user);
      this.loading= false;
      this.closed();
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>
