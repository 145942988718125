import gql from 'graphql-tag';

export const CREATE_DOMICILIO = gql`
mutation CreateDomicilio($colonia: ID! $nombre: String! $numero: String!){
 createDomicilio(input: { colonia:$colonia nombre:$nombre numero:$numero}) {
  domicilio{ 
   id
   nombre
   numero
   colonia{
    id
    nombre
   }
  }
}
}
`;

export const UPDATE_DOMICILIO = gql`
mutation UpdateDomicilio($id: ID! $colonia: ID! $nombre: String! $numero:String!){
 updateDomicilio(id:$id input: { colonia:$colonia, nombre:$nombre, numero:$numero }) {
  domicilio{
    id
    nombre
    numero
    colonia{
     id
     nombre
    }
  }
}
}
`;

export const DELETE_DOMICILIO = gql`
mutation DeleteDomicilio($id: ID!){
deleteDomicilio(id:$id) {
  found
}
}
`;
