import gql from 'graphql-tag';

export const CREATE_EMPRESA = gql`
mutation CreateEmpresa($domicilio: ID $nombre: String!){
 createEmpresa(input: { domicilio:$domicilio nombre:$nombre}) {
  empresa{ 
   id
   nombre
    domicilio{
            id
            nombre
            numero
        }
  }
}
}
`;

export const UPDATE_EMPRESA = gql`
mutation UpdateEmpresa($id: ID! $domicilio: ID $nombre: String!){
 updateEmpresa(id:$id input: { domicilio:$domicilio, nombre:$nombre}) {
  empresa{
    id
    nombre
     domicilio{
            id
            nombre
            numero
        }
  }
}
}
`;

export const DELETE_EMPRESA = gql`
mutation DeleteEmpresa($id: ID!){
deleteEmpresa(id:$id) {
  found
}
}
`;
