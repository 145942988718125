<template>
  <div id="pageDashboard" class="disableLinkEfects">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12 class="calculateMargin">
          <v-card
              class="mx-2 elevation-5"
              color="rgb(225, 225, 225, 0.5)"
              data-aos="fade"
              data-aos-duration="2000"
              data-aos-once="true"
          >
            <v-card-text class="text-xs-center text--accent-5">
              <h1 class="primary--text">GMR</h1>
              <h3 class="text-xs-center text--accent-2 pb-3">
                Gestión del personal de rondines.
              </h3>
              <v-btn
                  small
                  round
                  text
                  outlined
                  color="secondary"
                  style="font-size: 12px"
                  @click="$vuetify.goTo('#first', { duration: 1000, offset: 100 })"
              >
                Comenzar
                <v-icon small>fa-arrow-down</v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-flex>

      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "DashboardGmr",
  data () {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.calculateMargin {
  margin-bottom: calc(100vh / 2 - 155px);
}

.slide-enter-active {
  animation: slide-in 1s ease-out forwards;
}
.slide-live-active {
  animation: slide-out 1s ease-out forwards;
}
@keyframes slide-in {
  from {
    transform: translateY(-30px);
  } to {
      transform: translateY(0);
    }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  } to {
      transform: translateY(-30px);
    }
}
</style>
