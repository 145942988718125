import gql from 'graphql-tag';

export const ALL_COUNTRIES = gql`
  query allPaises {
    allPaises {
    edges{
      node{
        id
        nombre
        code
      }
    }
  }
}
`;
