import gql from 'graphql-tag';

export const CREATE_CONDICION = gql`
mutation CreateCondicion($nombre: String!){
 createCondicion(input: { nombre:$nombre}) {
  condicion{ 
   id
   nombre
  }
}
}
`;

export const UPDATE_CONDICION = gql`
mutation UpdateCondicion($id: ID! $nombre: String!){
 updateCondicion(id:$id input: { nombre:$nombre}) {
  condicion{
    id
    nombre
  }
}
}
`;

export const DELETE_CONDICION = gql`
mutation DeleteCondicion($id: ID!){
deleteCondicion(id:$id) {
  found
}
}
`;
