import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { All_USER } from '../graphql/query/userQueries';
import {CREATE_USER_BY_ADMIN,VERIFY_ACCOUNT_BY_ADMIN, UPDATE_USER_BY_ADMIN, DELETE_ACCOUNT_BY_ADMIN } from '../graphql/mutation/userMutation';

Vue.use(Vuex);

const state = () => ({
    users: [],
});

const getters = {
    users: (state) => state.users,
};
const mutations = {
    SET_USERS: (state, payload) => state.users = payload,
    ADD_USER: (state, payload) => state.users.push(payload),
    UPDATE_USER: (state, payload) => state.users = state.users.map(el => (el.id === payload.id ? payload : el)),
    DELETE_USER:(state, payload) => state.users = state.users.filter(el => el.id !== payload.id),
};
const actions = {
    async allUsers({ commit } ) {
        try {
            const { data: { allUsers: { edges } } }  = await apolloClient
                .mutate({ mutation: All_USER });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_USERS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createUser({commit}, payload) {
        try {
            const { data: { createUserByAdmin: {user}} } = await apolloClient
                .mutate({mutation: CREATE_USER_BY_ADMIN, variables: {...payload}});
            let { id } = user;
            await apolloClient.mutate({mutation: VERIFY_ACCOUNT_BY_ADMIN, variables: { id }});

            commit('ADD_USER', user)
        }catch (e) {
         console.log(e);
        }
    },

    // eslint-disable-next-line no-unused-vars
    async updateUser({ commit }, payload) {
        // eslint-disable-next-line no-unused-vars
       try {
           const { data: { updateUserByAdmin: { user } } } = await apolloClient.mutate(
               { mutation: UPDATE_USER_BY_ADMIN, variables: { ...payload } },
           );
           commit('UPDATE_USER', user);
       }catch (e) {
           console.log(e);
       }

    },
    async deleteUser({commit}, payload) {
        try {
            const { data: { deleteAccountByAdmin: {found} } } = await apolloClient
                .mutate({mutation: DELETE_ACCOUNT_BY_ADMIN, variables: {...payload}});
            console.log(found)
            commit('DELETE_USER', payload)


        }catch (e) {
         console.log(e);
        }
    },

};
export default {
    state,
    getters,
    actions,
    mutations,
};
