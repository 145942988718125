<template>
  <div>
    <calendario2/>
  </div>
</template>

<script>
import Calendario2 from '../../components/Calendario/Calendario2'
export default {
  name: "Calendario",
  components: {Calendario2},

}
</script>

<style scoped>

</style>
