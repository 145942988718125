import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_LOCACIONES } from '../graphql/query/locacionQueries';
import { CREATE_LOCACION, UPDATE_LOCACION, DELETE_LOCACION } from '../graphql/mutation/locacionMutation';


Vue.use(Vuex);

const state = () => ({
    locaciones: [],
    pagination: [],
});

const getters = {
    locaciones: state => state.locaciones,
};

const mutations = {
    SET_LOCACIONES: (state, payload) => state.locaciones = payload,
    ADD_LOCACION: (state, payload) => state.locaciones.push(payload),
    DELETE_LOCACION: (state, payload) => state.locaciones = state.locaciones.filter(el => el.id !== payload.id),
    UPDATE_LOCACION: (state, payload) => state.locaciones = state.locaciones.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allLocaciones({ commit } ) {
        try {
            const { data: { allLocaciones: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_LOCACIONES });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_LOCACIONES', nodes);
        } catch (e) {
            console.log(e);
        }
    },


    async createLocacion({ commit }, payload ) {
        try {
            const { data: { createLocacion: { locacion } } }  = await apolloClient
                .mutate({ mutation: CREATE_LOCACION, variables: { ...payload }  });
            commit('ADD_LOCACION', locacion);
        } catch (e) {
            console.log(e);
        }
    },
    async updateLocacion({ commit }, payload ) {
        try {
            const { data: { updateLocacion: { locacion } } }  = await apolloClient
                .mutate({ mutation: UPDATE_LOCACION, variables: { ...payload }  });
            commit('UPDATE_LOCACION', locacion);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteLocacion({ commit }, payload ) {
        try {
            const { data: { deleteLocacion: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_LOCACION, variables: { ...payload }  });
            if (found){
                commit('DELETE_LOCACION', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
