import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_CONDICIONES } from '../graphql/query/condicionQueries';
import { CREATE_CONDICION, UPDATE_CONDICION, DELETE_CONDICION } from '../graphql/mutation/condicionMutation';


Vue.use(Vuex);

const state = () => ({
    condiciones: [],
    pagination: [],
});

const getters = {
    condiciones: state => state.condiciones,
};

const mutations = {
    SET_CONDICIONES: (state, payload) => state.condiciones = payload,
    ADD_CONDICION: (state, payload) => state.condiciones.push(payload),
    DELETE_CONDICION: (state, payload) => state.condiciones = state.condiciones.filter(el => el.id !== payload.id),
    UPDATE_CONDICION: (state, payload) => state.condiciones = state.condiciones.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allCondiciones({ commit } ) {
        try {
            const { data: { allCondiciones: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_CONDICIONES });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_CONDICIONES', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createCondicion({ commit }, payload ) {
        try {
            const { data: { createCondicion: { condicion } } }  = await apolloClient
                .mutate({ mutation: CREATE_CONDICION, variables: { ...payload }  });
            commit('ADD_CONDICION', condicion);
        } catch (e) {
            console.log(e);
        }
    },
    async updateCondicion({ commit }, payload ) {
        try {
            const { data: { updateCondicion: { condicion } } }  = await apolloClient
                .mutate({ mutation: UPDATE_CONDICION, variables: { ...payload }  });
            commit('UPDATE_CONDICION', condicion);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteCondicion({ commit }, payload ) {
        try {
            const { data: { deleteCondicion: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_CONDICION, variables: { ...payload }  });
            if (found){
                commit('DELETE_CONDICION', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
