<template>
  <v-dialog
      v-model="showForm"
      max-width="400px"
      transition="dialog-top-transition"
      persistent
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          <h4 class="title">{{title}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <div >
              <v-text-field
                  v-model="colonia.nombre"
                  label="Nombre"
              />
              <v-text-field
                  v-model="colonia.cp"
                  label="Código Postal"
              />
              <v-select
                  v-model="municipio"
                  :items="municipios"
                  item-text="nombre"
                  item-value="id"
                  label="Selecciona el Municipio"
                  chips
                  hint="Municipio"
                  persistent-hint
              ></v-select>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="terciary" @click="closed">
            Cancelar
          </v-btn>
          <v-btn v-if="title==='Actualizar Colonia'" color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
          <v-btn v-else color="secondary" @click="create" :loading="loading">
            Crear
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "ColoniaForm",
  props: {
    title: String,
    colonia: {},
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['municipios']),
  },
  data: ()=>({
    loading: false,
    municipio: {},
  }),
  mounted() {
    this.municipio = this.colonia.municipio ? this.colonia.municipio.id : null ;
  },
  methods: {
    async create() {
      this.loading= true;
      this.colonia.municipio = this.municipio;
      await this.$store.dispatch('createColonia', this.colonia);
      this.loading= false;
      this.closed();
    },
    async update() {
      this.loading= true;
      this.colonia.municipio = this.municipio;
      await this.$store.dispatch('updateColonia', this.colonia);
      this.loading= false;
      this.closed();
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>

