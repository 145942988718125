import gql from 'graphql-tag';

export const All_UEMPRESA = gql`
  query{
   allUsuarioEmpresa
  {
   edges{
    node{
      id
      user{
        id
        username
        groups{
          edges{
            node{
              id
              name
            }
          }
        }
      }
      empleado{
        id
        numero
        nombre
      }
      guardia{
        id
        nombre
      }
      chofer{
        id
        nombre
      }
      empresa{
        id 
        nombre
      }
    }
  }
  }
}
`;
