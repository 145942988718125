import gql from 'graphql-tag';

export const ALL_HORARIOS =gql`
  query allHorarios{
   allHorarios
      {
       edges{
        node{
          id
          nombre
          hora
          empresa{
            id
            nombre
          }
        }
      }
      }
  }
`;
