import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_EMPLEADOS, FILTER_EMPLEADOS } from '../graphql/query/empleadoQueries';
import { CREATE_EMPLEADO, UPDATE_EMPLEADO, DELETE_EMPLEADO } from '../graphql/mutation/empleadoMutation';


Vue.use(Vuex);

const state = () => ({
    empleados: [],
    filterEmpleados: [],
    pagination: {},
});

const getters = {
    empleados: state => state.empleados,
    pagination: state => state.pagination,
    filterEmpleados: state => state.filterEmpleados,
};

const mutations = {
    SET_EMPLEADOS: (state, payload) => state.empleados = payload,
    SET_PAG: (state, payload) => state.pagination = payload,
    SET_FEMPLEADOS: (state, payload) => state.filterEmpleados = payload,
    ADD_EMPLEADO: (state, payload) => state.empleados.push(payload),
    ADD_FEMPLEADO: (state, payload) => state.filterEmpleados.push(payload),
    DELETE_EMPLEADO: (state, payload) => state.empleados = state.empleados.filter(el => el.id !== payload.id),
    DELETE_FEMPLEADO: (state, payload) => state.filterEmpleados = state.filterEmpleados.filter(el => el.id !== payload.id),
    CLEAN_FEMPLEADO: (state) => state.filterEmpleados = [],
    UPDATE_EMPLEADO: (state, payload) => state.empleados = state.empleados.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allEmpleados({ commit } , payload) {
        try {
            const { data: { allEmpleados: { pageInfo, edges } } }  = await apolloClient
                .mutate({ mutation: ALL_EMPLEADOS, variables: { ...payload }});
            const nodes = edges.map((d)=> d.node);
            await commit('SET_EMPLEADOS', nodes);
            await commit('SET_PAG', pageInfo);

        } catch (e) {
            console.log(e);
        }
    },

    async filterEmpleados({ commit }, payload ) {
        try {
            const { data: { allEmpleados: { edges } } }  = await apolloClient
                .mutate({ mutation: FILTER_EMPLEADOS, variables: { ...payload } });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_FEMPLEADOS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    cleanFempleados({commit}){
      commit('CLEAN_FEMPLEADO');
    },

    qEmpleado({ commit }, payload) {
        commit('DELETE_FEMPLEADO', payload);
    },

    async createEmpleado({ commit }, payload ) {
        try {
            const { data: { createEmpleado: { empleado } } }  = await apolloClient
                .mutate({ mutation: CREATE_EMPLEADO, variables: { ...payload }  });
            commit('ADD_EMPLEADO', empleado);
        } catch (e) {
            console.log(e);
        }
    },
    async updateEmpleado({ commit }, payload ) {
        try {
            const { data: { updateEmpleado: { empleado } } }  = await apolloClient
                .mutate({ mutation: UPDATE_EMPLEADO, variables: { ...payload }  });
            commit('UPDATE_EMPLEADO', empleado);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteEmpleado({ commit }, payload ) {
        try {
            const { data: { deleteEmpleado: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_EMPLEADO, variables: { ...payload }  });
            if (found){
                commit('DELETE_EMPLEADO', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
