import gql from 'graphql-tag';

export const ALL_GUARDIAS = gql`
  query allGuardias {
    allGuardias
    {
        edges{
        node{
            id
            nombre
            apellidoPaterno
            apellidoMaterno
            celular
            empresa{
                id
                nombre
            }
            domicilio{
                id
                nombre
                numero
            }
        }
    }
    }
}
`;

export const FILTER_EMPLEADOS = gql`
  query allEmpleados($range: String $in: String) {
    allEmpleados(numero_Range: $range numero_In: $in){
      edges{
        node{
          id
          nombre
          apellidoPaterno
          apellidoMaterno
          empresa{
            id
            nombre
         }
        }
      }
    }
}
`;

export const GUARDIA = gql`
  query guardia($id: ID!) {
    guardia(id:$id) {
      id
      nombre
      apellidoPaterno
      apellidoMaterno
      celular
  }
}
`;


