<template>
  <v-dialog
      v-model="showForm"
      max-width="400px"
      transition="dialog-top-transition"
      persistent
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          <h4 class="title">{{title}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <div >
              <v-text-field
                  v-model="chofer.nombre"
                  label="Nombre"
              />
              <v-text-field
                  v-model="chofer.apellidoPaterno"
                  label="Primer Apellido"
              />
              <v-text-field
                  v-model="chofer.apellidoMaterno"
                  label="Segundo Apellido"
              />
              <v-text-field
                  v-model="chofer.celular"
                  label="Número Celular"
              />
              <v-text-field
                  v-model="chofer.licencia"
                  label="Número Licencia"
              />
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="chofer.caducidadLicencia"
                      label="Caducidad de la Licencia"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="chofer.caducidadLicencia"
                    @input="menu = false"
                    locale="es-es"
                >
                </v-date-picker>
              </v-menu>
              <v-autocomplete
                  v-model="taxi"
                  :items="taxis"
                  item-text="matricula"
                  item-value="id"
                  label="Selecciona su Automóvil"
                  chips
                  clearable
                  small-chips
                  hint="Automóvil"
                  persistent-hint
              />
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="terciary" @click="closed">
            Cancelar
          </v-btn>
          <v-btn v-if="title==='Actualizar Chofer'" color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
          <v-btn v-else color="secondary" @click="create" :loading="loading">
            Crear
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ChoferForm",
  props: {
    title: String,
    chofer: {},
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['taxis']),
  },
  mounted() {
    this.taxi = this.chofer.taxi ? this.chofer.taxi.id : null ;
    if(this.taxis.length<=0){
      this.loadData();
    }
  },
  data: ()=>({
    loading: false,
    menu: false,
    taxi: {},
  }),
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("allTaxis");
      this.loading = false;
    },
    async create() {
      this.loading= true;
      this.chofer.taxi = this.taxi;
      await this.$store.dispatch('createChofer', this.chofer);
      this.loading= false;
      this.closed();
    },
    async update() {
      this.loading= true;
      this.chofer.taxi = this.taxi;
      await this.$store.dispatch('updateChofer', this.chofer);
      this.loading= false;
      this.closed();
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>
