import gql from 'graphql-tag';

export const ALL_GROUPS = gql`
  query allGroups {
   allGroups{
    edges{
      node{
        id
        name
        permissions{
        edges{
          node{
            id
            name
          }
        }
      }
      }
    }
  }
 }
`;

export const ALL_PERMS = gql`
  query allPermissions {
   allPermissions{
    edges{
        node{
          id
          name
        }
      }
    }
 }
`;
