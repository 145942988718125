<template>
  <div class="wrap">
    <notifications></notifications>
    <v-app-bar app prominent :src="banner" >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
<!--      <v-app-bar-title>{{ $route.name }}</v-app-bar-title>-->
      <v-spacer></v-spacer>
<!--      <v-btn @click="toggleTheme" color="primary" class="mr-2">{{buttonText}}</v-btn>-->
      <v-btn icon dark @click="logOut">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
          v-model="drawer"
          floating
          fixed
          :src="bg"
          :permanent="drawer"
          expand-on-hover
      >
      <v-list dark color="primary">
        <v-list-item class="px-2" @click="drawer = !drawer">
          <v-icon>
            mdi-chevron-left
          </v-icon>
          <v-list-item-title>
            {{((user.firstName && user.lastName) ? user.firstName + ' ' + user.lastName : user.username) }}
          </v-list-item-title>

        </v-list-item>
      </v-list>


        <v-divider></v-divider>

        <v-list>
          <v-list-item
              v-for="item in items"
              color="primary"
              :key="item.title"
              link
              :to="item.to"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    <v-main>
      <v-container class="px-4 py-0 fill-height" fluid>
        <v-layout class="fill-height">
          <v-flex class="ml-12" grow align-self-center>
            <transition name="slide" mode="out-in" type="animation">
              <router-view></router-view>
            </transition>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <template>
      <v-footer fixed elevation="24">
        <v-img :src="footerIm"/>
      </v-footer>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import footer from '../../components/footer'

export default {
  name: "DashboardLayout",
  components: [footer],

  data () {
    return {
      drawer: false,
      image: require('../../assets/LOGO1.svg'),
      banner: require('../../assets/banner_ttn.png'),
      bg: require('../../assets/login.png'),
      footerIm: require('../../assets/linea.svg'),
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', to: '/dashboard' },
        { title: 'Mi Cuenta', icon: 'mdi-account', to: '/user' },
        { title: 'Usuarios', icon: 'mdi-account-group-outline', to: '/users' },
        { title: 'Asignaciones', icon: 'mdi-account-group-outline', to: '/asignacion' },
        { title: 'Roles', icon: 'mdi-account-multiple', to: '/groups' },
        { title: 'Direcciones', icon: 'mdi-map', to: '/direccion' },
        { title: 'Empresas', icon: 'mdi-domain', to: '/empresa' },
        { title: 'Departamentos', icon: 'mdi-office-building', to: '/departamento' },
        { title: 'Plantas', icon: 'mdi-home-city', to: '/planta' },
        { title: 'Colaboradores', icon: 'mdi-account-group', to: '/colaborador' },
        { title: 'Autos', icon: 'mdi-taxi', to: '/auto' },
        { title: 'Choferes', icon:'mdi-steering', to: '/chofer' },
        // { title: 'Solicitudes', icon:'mdi-clipboard-edit-outline', to: '/solicitud' },
        { title: 'Tipo Solicitud', icon:'mdi-clipboard-edit-outline', to: '/tipo-solicitud' },
        { title: 'Planificador', icon:'mdi-calendar', to: '/planificador' },
      ],
    }
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    logOut() {
      this.$store.dispatch('logOut')
          .then(() => this.$router.push('/'));
    },
  },
  mounted() {
    console.log(this.$route.fullPath)
  },
  computed: {
    ...mapGetters(['user']),
    buttonText() {
      return !this.$vuetify.theme.dark ? 'Go Dark' : 'Go Light'
    }
  },
}
</script>

<style scoped>

.slide-enter-active {
 animation: slide-in 200ms ease-out forwards;
}
.slide-live-active {
  animation: slide-out 200ms ease-out forwards;
}
@keyframes slide-in {
  from {
    transform: translateY(-30px);
  } to {
        transform: translateY(0);
      }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  } to {
    transform: translateY(-30px);
  }
}


</style>
