<template>
  <v-speed-dial
      v-model="fab"
      direction="left"
      open-on-hover
      transition="slide-x-reverse-transition"
  >
    <template v-slot:activator>
      <v-btn
          v-model="fab"
          color="blue darken-2"
          fab
          small
          dark
      >
        <v-icon small v-if="fab">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-view-grid-outline
        </v-icon>
      </v-btn>
    </template>
    <v-btn
        fab
        small
        dark
        color="green"
        @click="update"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-btn
        fab
        small
        dark
        color="indigo"
    >
      <v-icon>mdi-account-details</v-icon>
    </v-btn>
    <v-btn
        fab
        small
        dark
        color="red"
        @click="del"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>
export default {
  name: "TableAction",
  props: {
    item: {},
  },
  data: () => ({
    fab: false,
  }),
  methods: {
    update(){
      this.$emit('update', this.item)
    },
    del(){
      this.$emit('delaction', this.item )
    },
    list(){
      this.$emit('detail', this.item)
    },
  },
}
</script>

<style scoped>

</style>
