import gql from 'graphql-tag';

export const ALL_OPCIONES =gql`
  query allOpciones{
   allOpciones
      {
       edges{
        node{
          id
          nombre
        }
      }
      }
  }
`;
