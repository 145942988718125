<template>
  <div>
    <div>
      <v-toolbar color="primary" dark width="500px">
        <template v-slot:extension>
          <v-tabs
              v-model="tab"
              align-with-title
          >
            <v-tabs-slider color="terciary"></v-tabs-slider>

            <v-tab
                v-for="item in items"
                :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>

      </v-toolbar>
    </div>
    <v-divider/>
      <template>
        <v-tabs-items v-model="tab">
          <v-spacer></v-spacer>
          <v-tab-item
              v-for="item in items"
              :key="item"
          >
            <country-list v-show="item==='paises'"/>
            <estado-list v-show="item==='estados'"/>
            <municipio-list v-show="item==='municipios'"/>
            <colonia-list v-show="item==='colonias'"/>
            <domicilio-list v-show="item==='domicilios'"/>
          </v-tab-item>
        </v-tabs-items>
      </template>

  </div>
</template>

<script>
import CountryList from '../country/CountryList';
import EstadoList from '../estado/EstadoList';
import MunicipioList from '../municipio/MunicipioList';
import ColoniaList from '../colonia/ColoniaList';
import DomicilioList from '../domicilio/DomicilioList';

export default {
  name: "Direccionlist",
  components: {CountryList, EstadoList, MunicipioList, ColoniaList, DomicilioList},
  data:()=>({
    tab: null,
    items: [
      'paises', 'estados', 'municipios', 'colonias', 'domicilios'
    ],
  }),
}
</script>

<style scoped>

</style>
