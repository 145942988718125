import gql from 'graphql-tag';

export const CREATE_COLONIA = gql`
mutation CreateColonia($municipio: ID! $nombre: String! $cp: String!){
 createColonia(input: { municipio:$municipio nombre:$nombre cp:$cp}) {
  colonia{ 
   id
   nombre
   cp
   municipio{
        id
        nombre
        code
      }
  }
}
}
`;

export const UPDATE_COLONIA = gql`
mutation UpdateColonia($id: ID! $municipio: ID! $nombre: String! $cp:String!){
 updateColonia(id:$id input: { municipio:$municipio, nombre:$nombre, cp:$cp }) {
  colonia{
    id
    nombre
    cp
    municipio{
        id
        nombre
        code
    }
  }
}
}
`;

export const DELETE_COLONIA = gql`
mutation DeleteColonia($id: ID!){
deleteColonia(id:$id) {
  found
}
}
`;
